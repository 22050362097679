import React from 'react'
import processingContainerStyles from '../css/processing-blocks/BlockContainer.module.css'
import TableComponent from './table-component'

export default function TablePanel({ database, title, userList, showToolbarButtons, rowsClickable }) {
   return (
      <div>
         {userList === null ? (
            <div className={processingContainerStyles.blockContainer}>Loading...</div>
         ) : userList.length > 0 ? (
            <TableComponent
               title={title}
               data={userList}
               showToolbarButtons={showToolbarButtons}
               rowsClickable={rowsClickable}
            />
         ) : (
            <></>
         )}
      </div>
   )
}
