import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { downloadSTLs } from '../lib/process-data'
import LoadingButton from '@mui/lab/LoadingButton'
import DownloadIcon from '@mui/icons-material/Download'
import processingContainerStyles from '../css/processing-blocks/BlockContainer.module.css'
import titleStyles from '../css/processing-blocks/Title.module.css'
import counterStyles from '../css/processing-blocks/Counter.module.css'
import buttonStyles from '../css/processing-blocks/Button.module.css'

const STLDownloadPanel = ({ database }) => {
   console.log('database:', database)
   return (
      <>
         <div className={processingContainerStyles.blockContainer}>
            <div className={titleStyles.title}>STL Download</div>
            <div className={counterStyles.counter}>
               Number of Stored Sessions:
               <div className={counterStyles.counterNumber}>{database.stlManagement.stlUnprocessedNum}</div>
            </div>
            <div className={buttonStyles.button}>
               <LoadingButton
                  variant="contained"
                  size="large"
                  disabled={!database.stlManagement.stlUnprocessedNum}
                  loading={database.stlManagement.downloading}
                  loadingPosition="end"
                  endIcon={<DownloadIcon />}
                  onClick={() => downloadSTLs()}
               >
                  Download
               </LoadingButton>
            </div>
         </div>
      </>
   )
}

export default observer(STLDownloadPanel)
