import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';


export default function InfoPanel({user, position}) {

  return (
    <React.Fragment>
      <Typography align="center" component="h2" variant="h6" color="primary" gutterBottom>
      Manual Measure
      </Typography>
      <Grid container direction="row"  justifyContent="space-between" alignItems="center">
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Foot Position
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {position}
            </Typography>
          </Container>
        </Grid>
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Session ID
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {user.id}
            </Typography>
          </Container>
        </Grid>
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Version
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {user.footMeasurements.machineLearning.rightArch.version}
            </Typography>
          </Container>
        </Grid>
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              ML Capture Date
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {new Date(Math.abs(user.mlTimestamp * 1000)).toDateString()}
            </Typography>
          </Container>
        </Grid>
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Shoe Size
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {user.usMensShoeSize}
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
