import { makeAutoObservable, action } from 'mobx'
import firebase from 'firebase/compat/app'

export class OrdersDataHold {
   constructor() {
      makeAutoObservable(this, {
         toggleShowBbox: action,
         updateImageSize: action,
         updateImageBoundingClientRect: action,
         signOut: action,
      })
   }

   setUsersLists(finalReview, readyForManualMeasure, noScans, usersWithoutFootMeasurement, usersWithoutPurchased) {
      this.usersLists = {
         finalReview: finalReview,
         readyForManualMeasure: readyForManualMeasure,
         noScans: noScans,
         usersWithoutFootMeasurement: usersWithoutFootMeasurement,
         usersWithoutPurchased: usersWithoutPurchased,
      }
   }

   setUsersReQueList(usersToReQue) {
      this.usersToReQue = usersToReQue
   }

   setManufactoringEmailQueued(usersToQue) {
      this.usersToQueManufactoringEmail = usersToQue
   }

   decrementPosition() {
      if (this.measure.position > 0) {
         this.measure.position--
      }
   }

   incrementPosition() {
      if (this.measure.position < this.measure.positions.length - 1) {
         this.measure.position++
      }
   }

   setValidSessionID(value) {
      this.measure.sessionID = value
   }

   setUser(user) {
      this.measure.user = user
   }

   setPhoto(photo, position) {
      const positionIndex = this.measure.positions.indexOf(position)
      if (positionIndex > -1) {
         this.measure.photos[positionIndex] = photo
      }
   }

   setParamData(data, position) {
      const positionIndex = this.measure.positions.indexOf(position)
      if (positionIndex > -1) {
         this.measure.paramData[positionIndex] = data
      }
   }

   setLeftArchParamData(data) {
      this.measure.leftArchParamData = data
   }

   setRightArchParamData(data) {
      this.measure.rightArchParamData = data
   }

   setBbox(data, bbName) {
      this.measure.bboxes[this.measure.position][bbName] = {
         ...this.measure.bboxes[this.measure.position][bbName],
         ...data,
      }
   }

   setbboxByPosition(data, position, bbName) {
      const positionIndex = this.measure.positions.indexOf(position)
      if (positionIndex > -1) {
         this.measure.bboxes[positionIndex][bbName] = data
      }
   }

   toggleShowBbox(bboxName) {
      this.measure.bboxes[this.measure.position][bboxName].show =
         !this.measure.bboxes[this.measure.position][bboxName].show
   }

   setBboxesToML(
      user,
      convWidth = ordersDataHold.measure.imgConstraints.size.width,
      convHeight = ordersDataHold.measure.imgConstraints.size.height,
      naturalWidth = ordersDataHold.measure.naturalImageSize.width,
      naturalHeight = ordersDataHold.measure.naturalImageSize.height
   ) {
      if (user == null) {
         user = this.measure.user
      }
      console.log('setting for position: ' + this.measure.positions[this.measure.position])
      this.measure.bboxes[this.measure.position] = null
      this.measure.bboxes[this.measure.position] = convert2LiveBboxes(
         user.footMeasurements.machineLearning[this.measure.positions[this.measure.position]].bboxes,
         convWidth,
         convHeight,
         naturalWidth,
         naturalHeight
      )
      this.measure.isReset[this.measure.position] = true
      this.toggleBboxes()
   }

   toggleBboxes() {
      this.measure.bboxes[this.measure.position].foot.show = false
      this.measure.bboxes[this.measure.position].archfoot.show = false
      this.measure.bboxes[this.measure.position].ballfoot.show = false
      setTimeout(() => {
         this.turnBboxesOn()
      }, 1)
   }

   turnBboxesOn() {
      this.measure.bboxes[this.measure.position].foot.show = true
      this.measure.bboxes[this.measure.position].archfoot.show = true
      this.measure.bboxes[this.measure.position].ballfoot.show = true
   }

   currentBboxes() {
      return this.measure.bboxes[this.measure.position]
   }

   updateImgSize(size) {
      this.measure.imgConstraints.size = size
   }

   setNatualImageSize(width, height) {
      this.measure.naturalImageSize = { width, height }
   }

   updateImgBoundingClientRect(rect) {
      this.measure.imgConstraints.boundingClientRect = rect
   }

   setReset(value) {
      console.log(this.measure.isReset[this.measure.position])
      this.measure.isReset[this.measure.position] = value
   }

   setFlagged(value) {
      console.log('flagged', value)
      this.measure.flagged = value
   }

   setInitialLoadFalse(position) {
      this.measure.initialLoad[position] = false
   }

   computeParams() {
      const storageBboxes = convert2StorageBboxes(
         this.measure.bboxes[this.measure.position],
         this.measure.imgConstraints.size.width,
         this.measure.imgConstraints.size.height,
         this.measure.naturalImageSize.width,
         this.measure.naturalImageSize.height
      )
      this.measure.storageBboxes[this.measure.position] = storageBboxes
      this.setManualMeasurements(CalcParameters(storageBboxes))
      filterParameterPanelData(this.measure.user, this.measure.positions[this.measure.position])
      this.setParamData(
         filterParameterPanelData(this.measure.user, this.measure.positions[this.measure.position]),
         this.measure.positions[this.measure.position]
      )
   }

   setManualMeasurements(data) {
      const positionName = this.measure.positions[this.measure.position]

      this.measure.manualMeasurements[this.measure.position] = data

      this.measure.user.footMeasurements.manualMeasure = {}
      this.measure.user.footMeasurements.manualMeasure[positionName] = { ...data }
   }

   updateOnResize(
      convWidth = ordersDataHold.measure.imgConstraints.size.width,
      convHeight = ordersDataHold.measure.imgConstraints.size.height,
      naturalWidth = ordersDataHold.measure.naturalImageSize.width,
      naturalHeight = ordersDataHold.measure.naturalImageSize.height
   ) {
      const storageBboxes = convert2StorageBboxes(
         this.measure.bboxes[this.measure.position],
         convWidth,
         convHeight,
         naturalWidth,
         naturalHeight
      )
      this.measure.storageBboxes[this.measure.position] = storageBboxes
      this.measure.bboxes[this.measure.position] = null
      this.measure.bboxes[this.measure.position] = convert2LiveBboxes(
         storageBboxes,
         convWidth,
         convHeight,
         naturalWidth,
         naturalHeight
      )
      this.toggleBboxes()
   }

   setShoeSize(shoeSize) {
      const match = shoeSize.match(/(\d+)/)
      this.measure.shoeSize = parseInt(match[0])
   }

   setMeasurementUnit(unit) {
      this.measure.measurementUnit[this.measure.position] = unit
   }

   setSTLQueuedList(data) {
      this.usersLists.purchasedNotStlQueued = null
      this.usersLists.purchasedNotStlQueued = data
   }

   setStlUnprocessedNum(stlUnprocessedNum) {
      this.stlManagement.stlUnprocessedNum = stlUnprocessedNum
   }

   setStlDownloading(value) {
      this.stlManagement.downloading = value
   }

   setRhinoPendingNum(value) {
      this.rhinoManagement.numPendingRhino = value
   }

   setRhinoSendingRequest(value) {
      this.rhinoManagement.sendingRequest = value
   }

   setSignedIn(value) {
      this.auth.signedIn = value
   }

   setAuthed(value) {
      this.auth.authed = value
   }

   signOut() {
      firebase.auth().signOut()
      this.setAuthed(false)
      this.setSignedIn(false)
   }

   resetState() {
      this.measure = {
         validID: true,
         user: null,
         displayPositions: ['Left Arch', 'Right Arch'],
         positions: ['leftArch', 'rightArch'],
         photos: [null, null],
         naturalImageSize: null,
         paramData: [null, null],
         isReset: [true, true],
         position: 0,
         // These bboxes are the ones that are being displayed
         flagged: false,
         initialLoad: [true, true],
         shoeSize: null,
         manualMeasurements: [null, null],
         measurementUnit: ['px', 'px'],
         bboxes: [
            {
               foot: { show: true, pos: { x: 0, y: 0 }, size: { width: 100, height: 100 } },
               archfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 100, height: 100 } },
               ballfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 100, height: 100 } },
            },
            {
               foot: { show: true, pos: { x: 0, y: 0 }, size: { width: 20, height: 20 } },
               archfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 20, height: 20 } },
               ballfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 20, height: 20 } },
            },
         ],
         storageBboxes: [
            {
               foot: { bbox: [0, 0, 0, 0] },
               archfoot: { bbox: [0, 0, 0, 0] },
               ballfoot: { bbox: [0, 0, 0, 0] },
            },
            {
               foot: { bbox: [0, 0, 0, 0] },
               archfoot: { bbox: [0, 0, 0, 0] },
               ballfoot: { bbox: [0, 0, 0, 0] },
            },
         ],
         imgConstraints: {
            size: { width: 1080, height: 1920 },
            boundingClientRect: { left: 0, top: 0, right: 0, bottom: 0 },
         },
      }
   }

   measure = {
      validID: true,
      user: null,
      displayPositions: ['Left Arch', 'Right Arch'],
      positions: ['leftArch', 'rightArch'],
      photos: [null, null],
      naturalImageSize: { width: 0, height: 0 },
      paramData: [null, null],
      isReset: [true, true],
      position: 0,
      // These bboxes are the ones that are being displayed
      flagged: false,
      initialLoad: [true, true],
      shoeSize: null,
      manualMeasurements: [null, null],
      measurementUnit: ['px', 'px'],
      bboxes: [
         {
            foot: { show: true, pos: { x: 0, y: 0 }, size: { width: 100, height: 100 } },
            archfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 100, height: 100 } },
            ballfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 100, height: 100 } },
         },
         {
            foot: { show: true, pos: { x: 0, y: 0 }, size: { width: 20, height: 20 } },
            archfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 20, height: 20 } },
            ballfoot: { show: true, pos: { x: 0, y: 0 }, size: { width: 20, height: 20 } },
         },
      ],
      storageBboxes: [
         {
            foot: { bbox: [0, 0, 0, 0] },
            archfoot: { bbox: [0, 0, 0, 0] },
            ballfoot: { bbox: [0, 0, 0, 0] },
         },
         {
            foot: { bbox: [0, 0, 0, 0] },
            archfoot: { bbox: [0, 0, 0, 0] },
            ballfoot: { bbox: [0, 0, 0, 0] },
         },
      ],
      imgConstraints: {
         size: { width: 1080, height: 1920 },
         boundingClientRect: { left: 0, top: 0, right: 0, bottom: 0 },
      },
   }

   stlManagement = {
      stlUnprocessedNum: 0,
      downloading: false,
   }

   rhinoManagement = {
      numPendingRhino: 0,
      sendingRequest: false,
   }

   auth = {
      signedIn: false,
      authed: false,
   }

   usersLists = {
      finalReview: null,
      readyForManualMeasure: null,
      noScans: null,
      usersWithoutFootMeasurement: null,
      usersWithoutPurchased: null,
   }

   usersToReQue = null

   usersToQueManufactoringEmail = []
}

// This is the constant that we will use when we want to import the order store
export let ordersDataHold = new OrdersDataHold()

const CalcParameters = (storageBboxes) => {
   const dorsumHeight = storageBboxes.foot.bbox[3] - storageBboxes.foot.bbox[1]

   const midBallFoot = (storageBboxes.ballfoot.bbox[0] + storageBboxes.ballfoot.bbox[2]) / 2

   let archStart = null
   let archEnd = null
   let archHeightIndex = null
   let truncatedFootLength = null
   let footLength = null

   footLength = Math.abs(storageBboxes.foot.bbox[0] - storageBboxes.foot.bbox[2])

   if (ordersDataHold.measure.positions[ordersDataHold.measure.position] === 'leftArch') {
      const backOfFoot = storageBboxes.foot.bbox[0]
      // left arch (x1) - back of foot
      archStart = storageBboxes.archfoot.bbox[0] - backOfFoot

      // right arch - back of foot
      archEnd = storageBboxes.archfoot.bbox[2] - backOfFoot

      truncatedFootLength = midBallFoot - backOfFoot
   } else {
      const backOfFoot = storageBboxes.foot.bbox[2]

      // right arch (x2) - back of foot
      archStart = backOfFoot - storageBboxes.archfoot.bbox[2]

      // right arch - back of foot
      archEnd = backOfFoot - storageBboxes.archfoot.bbox[0]

      truncatedFootLength = backOfFoot - midBallFoot
   }

   archHeightIndex = (dorsumHeight / truncatedFootLength).toFixed(5)

   console.log('archStart', archStart)
   console.log('archEnd', archEnd)
   console.log('archHeightIndex', archHeightIndex)
   console.log('truncatedFootLength', truncatedFootLength)
   console.log('footLength', footLength)

   return {
      archStart: archStart,
      archEnd: archEnd,
      archHeightIndex: archHeightIndex,
      truncatedFootLength: truncatedFootLength,
      footLength: footLength,
   }
}

// A live BBox is one that is being displayed
const convert2LiveBboxes = (bboxes, convWidth, convHeight, naturalWidth, naturalHeight) => {
   // {
   //   foot: {show: true, pos: {x: 0, y: 0}, size: {width: 100, height: 100}},
   //   archfoot: {show: true, pos: {x: 0, y: 0}, size: {width: 100, height: 100}},
   //   ballfoot: {show: true, pos: {x: 0, y: 0}, size: {width: 100, height: 100}},
   // },
   const liveBboxes = {}
   console.log(bboxes)
   for (const bboxName of Object.keys(bboxes)) {
      const liveSizeX = bboxes[bboxName].bbox[2] - bboxes[bboxName].bbox[0]
      const liveSizeY = bboxes[bboxName].bbox[3] - bboxes[bboxName].bbox[1]
      // Original Image is 1080x1920
      console.log('Live Dims')
      console.log(naturalWidth, naturalHeight, convWidth, convHeight)

      const [scaledWidth, scaledHeight] = [convWidth / naturalWidth, convHeight / naturalHeight]
      console.log(scaledWidth, scaledHeight)
      // const [diffX, diffY] = [ordersDataHold.measure.imgConstraints.boundingClientRect.left - 0, ordersDataHold.measure.imgConstraints.boundingClientRect.top - 0];
      liveBboxes[bboxName] = {
         show: true,
         pos: { x: bboxes[bboxName].bbox[0] * scaledWidth, y: bboxes[bboxName].bbox[1] * scaledHeight },
         size: { width: liveSizeX * scaledWidth, height: liveSizeY * scaledHeight },
      }
      // liveBboxes[bboxName] = {show: true, pos: {x: bboxes[bboxName].bbox[0], y: bboxes[bboxName].bbox[1]}, size: {width: liveSizeX, height: liveSizeY}}
   }
   return liveBboxes
}

// A live BBox is one that is being displayed
const convert2StorageBboxes = (bboxes, convWidth, convHeight, naturalWidth, naturalHeight) => {
   // {
   //   foot: {show: true, pos: {x: 0, y: 0}, size: {width: 100, height: 100}},
   //   archfoot: {show: true, pos: {x: 0, y: 0}, size: {width: 100, height: 100}},
   //   ballfoot: {show: true, pos: {x: 0, y: 0}, size: {width: 100, height: 100}},
   // },

   //          y1
   //     _____________
   // x1 |             | x2
   //    |_____________|
   //
   //          y2

   const storageBboxes = {}
   console.log(bboxes)
   for (const bboxName of Object.keys(bboxes)) {
      // const x1 = bboxes[bboxName].pos.x - leftImage
      // const y1 = bboxes[bboxName].pos.y - topImage
      // const x2 = x1 + bboxes[bboxName].size.width
      // const y2 = y1 + bboxes[bboxName].size.height

      console.log('Storage Dims')
      console.log(naturalWidth, naturalHeight, convWidth, convHeight)

      const [scaledWidth, scaledHeight] = [naturalWidth / convWidth, naturalHeight / convHeight]

      const x1 = Math.round(bboxes[bboxName].pos.x * scaledWidth)
      const y1 = Math.round(bboxes[bboxName].pos.y * scaledHeight)
      const x2 = Math.round(x1 + bboxes[bboxName].size.width * scaledWidth)
      const y2 = Math.round(y1 + bboxes[bboxName].size.height * scaledHeight)

      storageBboxes[bboxName] = { bbox: [x1, y1, x2, y2] }
   }
   return storageBboxes
}

export const filterParameterPanelData = (user, position) => {
   let hasManualMeasurements = false
   let processedData = []
   for (const parameter of Object.keys(user.footMeasurements.machineLearning[position])) {
      if (
         parameter === 'archStart' ||
         parameter === 'archEnd' ||
         parameter === 'archHeightIndex' ||
         parameter === 'truncatedFootLength'
      ) {
         let machinePredicted = user.footMeasurements.machineLearning[position][parameter]
         if (parameter === 'archHeightIndex') {
            machinePredicted = machinePredicted.toFixed(5)
         }

         let manualMeasured = null
         let pctError = null
         try {
            manualMeasured = user.footMeasurements.manualMeasure[position][parameter]
            pctError = Math.round((Math.abs(machinePredicted - manualMeasured) / manualMeasured) * 100)
            // Convert to inches
            if (parameter !== 'archHeightIndex') {
               const footLength = user.footMeasurements.manualMeasure[position]['footLength']
               const shoeSize = user.usMensShoeSize
               manualMeasured = PixelToInches(manualMeasured, footLength, parseFloat(shoeSize)).toFixed(3)
               machinePredicted = PixelToInches(machinePredicted, footLength, parseFloat(shoeSize)).toFixed(3)
               hasManualMeasurements = true
            }
         } catch {
            // If the key could not be accessed we want to still add the manual results to the processedData array
            manualMeasured = 'Not Available'
            pctError = NaN
         }
         processedData.push({
            parameterName: parameter,
            machinePredicted: machinePredicted,
            manualMeasured: manualMeasured,
            pctError: pctError,
         })
      }
   }
   if (hasManualMeasurements) {
      ordersDataHold.setMeasurementUnit('in')
   }
   return processedData.sort((a, b) => b.pctError - a.pctError)
}

function PixelToInches(value, footLengthPixels, shoeSize) {
   const footLengthInches = 0.323 * shoeSize + 7.36

   return (value / footLengthPixels) * footLengthInches
}
