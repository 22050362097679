import * as React from 'react'
import { useState, useEffect } from 'react'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import DashboardIcon from '@mui/icons-material/Dashboard'
import Button from '@mui/material/Button'
import { ordersDataHold } from '../lib/orders-data-hold'
import { addTestUserOrder } from '../lib/create-test-order'
import { onValue, ref, query, orderByChild, equalTo } from 'firebase/database'
import { db } from '../../utils/firebase'

export default function AppBar({ title }) {
   const [showDevTools, setShowDevTools] = useState(false)
   const [showTools, setShowTools] = useState(false)
   const [userCount, setUserCount] = useState(1)
   const [inputVal, setInputVal] = useState('')
   const [instrydeOrderNum, setInstrydeOrderNum] = useState('')

   useEffect(() => {
      if (process.env.REACT_APP_ADD_TEST_USER_ORDER_VISIBLE.toLowerCase() === 'true') {
         setShowDevTools(true)
      }
   }, [])

   function addTestUserOrderClicked() {
      const count = userCount
      addTestUserOrder(count)
      setUserCount(count + 1)
   }

   function handleOnChange(event) {
      console.log('handleonchange called')
      const value = event.target.value
      setInputVal(value)
   }

   function handleOnKeyDown(event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
         findInstrydeOrderNumber()
      }
   }

   function findInstrydeOrderNumber() {
      console.log('want to find the number')
      const userDBRef = query(ref(db, 'Users/'), orderByChild('partnerName'), equalTo('protect3d')) //ref(db, 'Users/')
      onValue(
         userDBRef,
         async (snapshot) => {
            const users = Object.values(snapshot.val())
            const userToFind = users.filter((user) => user.customerID === inputVal)[0] ?? { orderNumber: 'None Found' }
            const orderNumber = userToFind.orderNumber
            setInstrydeOrderNum(orderNumber)
         },
         { onlyOnce: true }
      )
   }

   return (
      <React.Fragment>
         <MuiAppBar position="fixed">
            <Toolbar
               sx={{
                  pr: '24px', // keep right padding when drawer closed
               }}
            >
               <div
                  style={{
                     marginRight: '28px',
                  }}
                  onClick={() => setShowTools(!showTools)}
               >
                  <DashboardIcon />
               </div>
               <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
               >
                  {title}
               </Typography>
               {showDevTools && (
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'auto',
                        height: '50px',
                        color: 'white',
                        userSelect: 'none',
                        marginRight: '10px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                     }}
                     onClick={() => addTestUserOrderClicked()}
                  >
                     Add Test Order
                  </div>
               )}
               <Button color="inherit" onClick={() => ordersDataHold.signOut()}>
                  Logout
               </Button>
            </Toolbar>
            {showTools && (
               <div
                  style={{
                     display: 'flex',
                     height: '50px',
                     width: '100%',
                     alignItems: 'center',
                  }}
               >
                  <div style={styleSheet.toolElem}>Protect3d Order Lookup</div>
                  <input
                     style={styleSheet.inputElem}
                     type={'text'}
                     placeholder={'Enter Order #'}
                     value={inputVal}
                     onKeyDown={handleOnKeyDown}
                     onChange={handleOnChange}
                  />
                  <div style={styleSheet.toolElem} onClick={() => findInstrydeOrderNumber()}>
                     Submit
                  </div>

                  <div style={styleSheet.toolElem}>Instryde Order #:</div>
                  <div style={styleSheet.toolElem}>{instrydeOrderNum}</div>
               </div>
            )}
         </MuiAppBar>
      </React.Fragment>
   )
}

const styleSheet = {
   toolElem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
      height: '50px',
      color: 'white',
      userSelect: 'none',
      marginRight: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
   },
   inputElem: {
      width: 'auto',
      height: '30px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      paddingLeft: '8px',
      border: 'none',
      backgroundColor: 'white',
      borderRadius: '5px',
      /* identical to box height, or 171% */
      color: '#002A4A',
   },
}
