import tableStyles from '../css/table-blocks/TableStyle.module.css'

export default function TableRow({
   index,
   hideSelectedColumns,
   row,
   rowsChecked,
   handleRowClick,
   handleRowCheckClick,
}) {
   return (
      <tr className={tableStyles.tableRowHover} onClick={() => handleRowClick(row.id)}>
         <td>
            <input
               type="checkbox"
               data-testid="rowCheckBox"
               id={row.id}
               onChange={(event) => handleRowCheckClick(event, index)}
               checked={rowsChecked.includes(row.id)}
               onClick={(e) => e.stopPropagation()}
            />
         </td>
         {!hideSelectedColumns.includes('id') && <td>{row.id}</td>}
         {!hideSelectedColumns.includes('orderNumber') && <td>{row.orderNumber}</td>}
         {!hideSelectedColumns.includes('protect3dCID') && <td>{row.customerID}</td>}
         {!hideSelectedColumns.includes('firstName') && <td>{row.firstName}</td>}
         {!hideSelectedColumns.includes('email') && <td>{row.email}</td>}
         {!hideSelectedColumns.includes('partnerName') && <td>{row?.partnerName ?? ''}</td>}
         {!hideSelectedColumns.includes('mlTimestamp') && (
            <td>{new Date(Math.abs(row.mlTimestamp * 1000)).toDateString()}</td>
         )}
      </tr>
   )
}
