import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import Title from '../components/Title';
import styles from "../css/Table.module.css";
import { Link } from "react-router-dom";


export default function TableComponent({data}) {
  return (
    <React.Fragment>
      <Title>Overview</Title>
      <Table size="small" component='div'>
        <TableHead component='div'>
          <TableRow component='div'>
            <TableCell component='div'>Parameter</TableCell>
            <TableCell component='div' align="right">Average Percent Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component='div'>
          {data.map((row) => (
            <TableRow className={styles.tableRow} component={Link} to={`/ml-analytics/parameter/${row.targetName}`} key={row.targetName}>
              <TableCell component='div'>{row.targetName}</TableCell>
              <TableCell component='div' align="right">{`${row.pctError}%`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
