import * as React from 'react';
import { styled, alpha} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import pullFirebaseData, { analyticsDataHold } from '../lib/process-data';

import {useNavigate, Link as LinkTo} from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
}));


export default function AppBar({title}) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <MuiAppBar position="fixed">
                <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
                >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="go home"
                    component={LinkTo} 
                    to={'/ml-analytics'}
                    sx={{
                    marginRight: '28px',
                    }}
                    
                >
                    <DashboardIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                    {title}
                </Typography>
                <Search>
                    <SearchIconWrapper>
                    <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            pullFirebaseData('session', analyticsDataHold, e.target.value);
                            navigate(`/ml-analytics/session/${e.target.value}`, {sessionIDChange: e.target.value})
                        }
                    }}
                    placeholder="Lookup sessionID..."
                    inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
                </Toolbar>
            </MuiAppBar>
        </React.Fragment>
    );
}
