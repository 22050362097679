import { onValue, ref, update, query, orderByChild, equalTo } from 'firebase/database'
import { getDownloadURL, listAll, getBlob, uploadBytes, deleteObject, ref as sref } from 'firebase/storage'
import { db, stlDB, userImagesBucket, stlQueueOutputBucket } from '../../utils/firebase'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import { filterParameterPanelData, ordersDataHold } from './orders-data-hold'

export const fetchSessionInfo = (sessionID) => {
   const userDBRef = ref(db, 'Users/')
   onValue(userDBRef, (snapshot) => {
      if (!snapshot.hasChild(sessionID)) {
         ordersDataHold.setValidSessionID(false)
      } else {
         const user = snapshot.val()[sessionID]
         ordersDataHold.setUser(user)
         ordersDataHold.setShoeSize(user.usMensShoeSize)
         ordersDataHold.setParamData(filterParameterPanelData(user, 'leftArch'), 'leftArch')
         ordersDataHold.setParamData(filterParameterPanelData(user, 'rightArch'), 'rightArch')

         const leftArchPhotoRef = sref(userImagesBucket, `/${sessionID}/LeftArch.jpg`)
         getDownloadURL(leftArchPhotoRef).then((url) => {
            ordersDataHold.setPhoto(url, 'leftArch')
         })
         const rightArchPhotoRef = sref(userImagesBucket, `/${sessionID}/RightArch.jpg`)
         getDownloadURL(rightArchPhotoRef).then((url) => {
            ordersDataHold.setPhoto(url, 'rightArch')
         })
      }
   })
}

export const submitMeasure = () => {
   const manualMeasure = {
      leftArch: {
         bboxes: ordersDataHold.measure.storageBboxes[0],
         archEnd: ordersDataHold.measure.manualMeasurements[0].archEnd,
         archStart: ordersDataHold.measure.manualMeasurements[0].archStart,
         archHeightIndex: ordersDataHold.measure.manualMeasurements[0].archHeightIndex,
         truncatedFootLength: ordersDataHold.measure.manualMeasurements[0].truncatedFootLength,
         footLength: ordersDataHold.measure.manualMeasurements[0].footLength,
      },
      rightArch: {
         bboxes: ordersDataHold.measure.storageBboxes[1],
         archEnd: ordersDataHold.measure.manualMeasurements[1].archEnd,
         archStart: ordersDataHold.measure.manualMeasurements[1].archStart,
         archHeightIndex: ordersDataHold.measure.manualMeasurements[1].archHeightIndex,
         truncatedFootLength: ordersDataHold.measure.manualMeasurements[1].truncatedFootLength,
         footLength: ordersDataHold.measure.manualMeasurements[1].footLength,
      },
   }
   let updates = {}
   updates['/Users/' + ordersDataHold.measure.user.id + '/flaggedForMLReview'] = ordersDataHold.measure.flagged
   updates['/Users/' + ordersDataHold.measure.user.id + '/manuallyMeasured'] = true
   updates['/Users/' + ordersDataHold.measure.user.id + '/footMeasurements/manualMeasure'] = manualMeasure
   update(ref(db), updates)
   ordersDataHold.resetState()
}
export const downloadSTLs = async () => {
   ordersDataHold.setStlDownloading(true)
   let jszip = new JSZip()
   const processedFolder = jszip.folder('processed')
   const listRef = sref(stlQueueOutputBucket, '/unprocessed/')
   const ordersRef = await listAll(listRef)
   // Get all files under the unprocessed folder
   let fileRefs = await Promise.all(
      ordersRef.prefixes.map(async (prefix) => {
         return await listAll(prefix)
      })
   )
   // Combine all the files into one array
   fileRefs = fileRefs.flatMap((x) => x.items)

   if (fileRefs.length !== 0) {
      const downloadedFiles = await Promise.all(
         fileRefs.map(async (file) => {
            const blob = await getBlob(file)
            const uploadRef = sref(stlQueueOutputBucket, file.fullPath.replace('unprocessed/', 'processed/'))
            await uploadBytes(uploadRef, blob)
            await deleteObject(file)
            return blob
         })
      )

      const orderNumbers = await getOrderNumbers(fileRefs.map((ref) => ref.fullPath))
      const downloadedCSVs = await generateCSVs(orderNumbers)
      const newSaveRef = fileRefs.map((fileRef) => {
         return fileRef.fullPath.replace('unprocessed/', '')
      })

      downloadedFiles.forEach((file, i) => {
         processedFolder.file(`${newSaveRef[i]}`, file)
      })
      downloadedCSVs.singleCSVs.forEach((file, i) => {
         processedFolder.file(`${orderNumbers[i]}/${orderNumbers[i]}.csv`, file)
      })
      processedFolder.file('All_Orders.csv', downloadedCSVs.masterCSV)

      const content = await jszip.generateAsync({ type: 'blob' })
      saveAs(content, 'stl.zip')

      console.log('orderNumbers', orderNumbers)
      const userIDs = await getUserIDs(orderNumbers)

      console.log('userIDs', userIDs)

      userIDs.forEach((userID) => {
         let updates = {}
         updates['/Users/' + userID + '/stlDownloaded'] = true
         updates['/Users/' + userID + '/manufactoringEmailQueued'] = true
         update(ref(db), updates)
      })
   }
   ordersDataHold.setStlDownloading(false)
   // // Set the number of files in the unprocessed folder to 0
   update(ref(stlDB), { numInStorage: 0 })
}

export const startRhinoGCP = () => {
   ordersDataHold.setRhinoSendingRequest(true)
   var formdata = new FormData()
   formdata.append('authKey', 'DBgfYzR9sJ39VjEy')
   fetch('https://api.instryde.com/start_rhino_gcp', { method: 'POST', body: formdata })
      .then((response) => response.text())
      .then((result) => {
         ordersDataHold.setRhinoSendingRequest(false)
         console.log(result)
      })
      .catch((error) => {
         ordersDataHold.setRhinoSendingRequest(false)
         alert('The rhino GCP service could not be started. Check console logs for more information.')
         console.log('error', error)
      })
}

const generateCSVs = async (orderNumbers) => {
   const csvs = []
   const masterCSVRows = [
      ['Order#', 'Quantity', 'Name', 'Street', 'Street 2', 'City', 'State', 'Zip', 'Country', 'Full Address'],
   ]

   await Promise.all(
      orderNumbers.map(async (orderNumber) => {
         let headerRow = [
            'Order#',
            'Quantity',
            'Name',
            'Street',
            'Street 2',
            'City',
            'State',
            'Zip',
            'Country',
            'Full Address',
         ]
         let rows = []
         const usersRef = query(ref(db, 'Users/'), orderByChild('orderNumber'), equalTo(orderNumber))

         await new Promise((resolve) => {
            onValue(
               usersRef,
               async (snapshot) => {
                  const users = snapshot.val()

                  if (users === null) {
                     return null
                  }

                  for (const user of Object.values(users)) {
                     const orderNumber = user.orderNumber
                     const quantity = 1
                     const name = `${user.shipping_address.first_name} ${user.shipping_address.last_name}`
                     const street1 = user.shipping_address.address1
                     const street2 = user.shipping_address.address2 ?? ''
                     const city = user.shipping_address.city
                     const state = user.shipping_address.province
                     const stateCode = user.shipping_address.province_code
                     const zip = user.shipping_address.zip
                     const country = user.shipping_address.country
                     const fullAddress = `"${name} ${street1} ${street2} ${city}, ${stateCode} ${zip}"`
                     rows.push([orderNumber, quantity, name, street1, street2, city, state, zip, country, fullAddress])
                  }

                  resolve()
               },
               { onlyOnce: true }
            )
         })
         const csvContent = headerRow.join(',') + '\n' + rows.map((e) => e.join(',')).join('\n')

         csvs.push(csvContent)
         masterCSVRows.push(rows.map((row) => row))
      })
   )

   const masterCSV = masterCSVRows.map((e) => e.join(',')).join('\n')

   return { singleCSVs: csvs, masterCSV: masterCSV }
}

const getOrderNumbers = async (pathsRefs) => {
   const leftArchFileRefs = pathsRefs.filter((ref) => ref.includes('_L_'))
   const orderNumbers = leftArchFileRefs.map((fileRef) => {
      const split = fileRef.split('/')
      return Number(split[1])
   })
   return orderNumbers
}

const getUserIDs = async (orderNumbers) => {
   let userIDs = []
   await Promise.all(
      orderNumbers.map(async (orderNumber) => {
         const usersRef = query(ref(db, 'Users/'), orderByChild('orderNumber'), equalTo(orderNumber))

         await new Promise((resolve) => {
            onValue(
               usersRef,
               async (snapshot) => {
                  const users = snapshot.val()

                  console.log('users snapshot: ', users)

                  if (users === null) {
                     return null
                  }

                  const userIDList = Object.keys(users)

                  if (userIDList.length > 1) {
                     console.log(
                        'More than one user was picked up. As a result, we did NOT que these users for manufactoring email: ',
                        users
                     )
                     alert('check console logs')
                     return null
                  }

                  if (userIDList.length === 1) {
                     console.log('user located: ', userIDList[0])
                     userIDs.push(userIDList[0])
                  }

                  resolve()
               },
               { onlyOnce: true }
            )
         })
      })
   )
   return userIDs
}
