import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import AppBar from '../components/AppBar';
import Chart from '../components/Chart';
import pullFirebaseData, { analyticsDataHold } from '../lib/process-data';
import Table from './Table';


const mdTheme = createTheme();

const DashboardContent = observer(({database}) => {

  React.useEffect(() => {
    pullFirebaseData('overview', database);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar title="inStryde ML Analytics Dashboard" />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12}>
                <Paper style={{height: "100%", width: "100%"}}
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 400,
                  }}
                >
                  {database.overview.chartData != null ? <Chart chartData={database.overview.chartData} title={"Overview"} /> : <div>Loading...</div>}
                </Paper>
              </Grid>
              {/* Table */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  {database.overview.tableData != null ? <Table data={database.overview.tableData} /> : <div>Loading...</div>}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
})


const OverviewDashboard = () => {
  return (<DashboardContent database={analyticsDataHold}/>)
}

export default OverviewDashboard;
