import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { fetchSessionInfo } from '../lib/process-data'
import { ordersDataHold } from '../lib/orders-data-hold'
import InfoPanel from './Panels/InfoPanel'
import BoundingBoxPanel from './Panels/BoundingBoxPanel'
import ParameterInfo from './Panels/ParameterInfoPanel'
import BoundingBoxButtons from './Panels/BoundingBoxButtons'
import NavigationButtons from './Panels/NavigationButtons'
import ImagePanel from './Panels/ImagePanel'

const mdTheme = createTheme()

const ListContent = observer(({ database }) => {
   const params = useParams()

   React.useEffect(() => {
      fetchSessionInfo(params.sessionID)
   }, []) // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <ThemeProvider theme={mdTheme}>
         <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box
               component="main"
               sx={{
                  backgroundColor: (theme) =>
                     theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'hidden',
               }}
            >
               <Container sx={{ mt: 1, mb: 1 }} style={{ maxWidth: '1800px' }}>
                  <Grid container wrap="nowrap" direction="row" spacing={3}>
                     {/* Bounding Box Image */}
                     <Grid item style={{ flexShrink: 0, maxWidth: '57vh', minWidth: '475px' }}>
                        <Paper
                           style={{ width: '100%' }}
                           sx={{
                              p: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                           }}
                        >
                           {database.measure.photos[database.measure.position] != null ? (
                              <ImagePanel database={database} />
                           ) : null}
                        </Paper>
                     </Grid>
                     {/* Right Whole Panel */}
                     <Grid item>
                        <Grid container wrap="nowrap" direction="column" spacing={1} sx={{ maxHeight: '100%' }}>
                           {/* Manual Measure Session Info */}
                           <Grid item sx={{ width: '100%' }}>
                              <Paper
                                 style={{ height: '100%', width: '100%' }}
                                 sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 100,
                                 }}
                              >
                                 {database.measure.user != null ? (
                                    <InfoPanel
                                       user={database.measure.user}
                                       position={database.measure.displayPositions[database.measure.position]}
                                    />
                                 ) : null}
                              </Paper>
                           </Grid>
                           <Grid item>
                              <Grid container direction="row" spacing={1}>
                                 {/* BBox Selection Panel */}
                                 <Grid item xs={6}>
                                    <Paper
                                       style={{ height: '100%', width: '100%' }}
                                       sx={{
                                          p: 2,
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                    >
                                       {database.measure.user != null ? (
                                          <BoundingBoxButtons database={database} />
                                       ) : null}
                                    </Paper>
                                 </Grid>
                                 {/* Bounding Box Panel */}
                                 <Grid item xs={6}>
                                    <Paper
                                       style={{ height: '100%', width: '100%' }}
                                       sx={{
                                          p: 2,
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                    >
                                       {database.measure.user != null ? (
                                          <BoundingBoxPanel
                                             user={database.measure.user}
                                             position={database.measure.positions[database.measure.position]}
                                          />
                                       ) : null}
                                    </Paper>
                                 </Grid>
                              </Grid>
                           </Grid>
                           {/* Parameter Panel */}
                           <Grid item sx={{ width: '100%' }}>
                              <Paper
                                 style={{ height: '100%', width: '100%' }}
                                 sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 100,
                                 }}
                              >
                                 {database.measure.user != null ? <ParameterInfo data={database.measure} /> : null}
                              </Paper>
                           </Grid>
                           {/* Navigation Panel */}
                           <Grid item sx={{ width: '100%' }}>
                              <Paper
                                 style={{ height: '100%', width: '100%' }}
                                 sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 100,
                                 }}
                              >
                                 {database.measure.user != null ? <NavigationButtons database={database} /> : null}
                              </Paper>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>
      </ThemeProvider>
   )
})

const UserList = () => {
   return <ListContent database={ordersDataHold} />
}

export default UserList
