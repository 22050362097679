import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from 'chart.js';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { default as TitleComponent } from './Title';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    }
  },
};

const labels = ['0-9%', '10-19%', '20-29%', '30-39%', '40-49%', '50-59%', '60-69%', '70-79%', '80-89%', '90-100%'];


export default function Chart({chartData, title}) {

  const data = {
    labels,
    datasets: [
      {
        label: 'Pct Error Chart',
        data: chartData,
        backgroundColor: 'rgba(58, 115, 205, 0.8)',
      }
    ],
  };

  return (
    <React.Fragment>
        <TitleComponent>{title}</TitleComponent>
        <div style={{height: "300px"}}>
          <Bar options={options} data={data} />
        </div>
    </React.Fragment>
  );
}
