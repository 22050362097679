import React from 'react'

import toolbarButtonStyles from '../css/table-blocks/ToolbarButtons.module.css'

export default function Button({ title, image, buttonFunction }) {
   return (
      <div data-testid="toolbarButton" className={toolbarButtonStyles.button} onClick={buttonFunction}>
         <div className={toolbarButtonStyles.tooltipText}>{title}</div>
         {image}
      </div>
   )
}
