import { ref, update } from 'firebase/database'
import { uploadBytes, ref as sref } from 'firebase/storage'
import { db, userImagesBucket } from '../../utils/firebase'
import LeftArch from '../../images/LeftArch.jpg'
import LeftHeel from '../../images/LeftHeel.jpg'
import RightArch from '../../images/RightArch.jpg'
import RightHeel from '../../images/RightHeel.jpg'

export const addTestUserOrder = async (userIndex) => {
   let updates = {}
   console.log('calling addTestUserOrder')
   const testUser = testUserTemplate(userIndex)
   updates[`/Users/TEST${userIndex}`] = testUser
   updates[`/purchases/TEST${userIndex}`] = { orderNumber: userIndex }

   uploadUserImagesToFB(userIndex).then(() => {
      update(ref(db), updates)
   })
}

const uploadUserImagesToFB = async (userIndex) =>
   new Promise((resolve, reject) => {
      toDataURL(LeftArch, async function (dataUrl) {
         await uploadUserImageToFB(dataUrl, userIndex, 'LeftArch.jpg')
         toDataURL(LeftHeel, async function (dataUrl) {
            await uploadUserImageToFB(dataUrl, userIndex, 'LeftHeel.jpg')
            toDataURL(RightArch, async function (dataUrl) {
               await uploadUserImageToFB(dataUrl, userIndex, 'RightArch.jpg')
               toDataURL(RightHeel, async function (dataUrl) {
                  await uploadUserImageToFB(dataUrl, userIndex, 'RightHeel.jpg')
                  resolve()
               })
            })
         })
      })
   })

async function uploadUserImageToFB(dataUrl, userIndex, filename) {
   const blobImage = b64toBlob(dataUrl)
   const uploadRef = sref(userImagesBucket, `/TEST${userIndex}/` + filename)
   await uploadBytes(uploadRef, blobImage)
}

function toDataURL(src, callback, outputFormat) {
   var img = new Image()
   img.crossOrigin = 'Anonymous'
   img.onload = function () {
      var canvas = document.createElement('CANVAS')
      var ctx = canvas.getContext('2d')
      var dataURL
      canvas.height = this.naturalHeight
      canvas.width = this.naturalWidth
      ctx.drawImage(this, 0, 0)
      dataURL = canvas.toDataURL(outputFormat)
      callback(dataURL)
   }
   img.src = src
   if (img.complete || img.complete === undefined) {
      img.src = 'data:image/jpg;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
      img.src = src
   }
}

const b64toBlob = (base64Image) => {
   // Extract the Base64 data (remove the data:image/png;base64, prefix)
   const base64Data = base64Image.split(';base64,').pop()

   // Decode the Base64 data into a binary string
   const binaryString = window.atob(base64Data)

   // Convert the binary string to a Uint8Array
   const uint8Array = new Uint8Array(binaryString.length)
   for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
   }

   // Create a Blob from the Uint8Array
   const imageBlob = new Blob([uint8Array], { type: 'image/jpg' })

   return imageBlob
}

const testUserTemplate = (userIndex) => {
   return {
      accessHealthUser: false,
      age: '29',
      basePainPointsLeft: ['Left No pain on either side'],
      basePainPointsRight: ['Right No pain on either side'],
      bonusInfo: '',
      checkout_token: 'cbc4f9156bda67d7821277abd4940e47',
      diabetic: '',
      email: 'a.plant@instryde.com',
      firstName: 'test',
      footMeasurements: {
         machineLearning: {
            leftArch: {
               archEnd: 570,
               archHeight: 52,
               archHeightIndex: 0.4675118858954041,
               archStart: 174,
               archStartYolo: 194,
               ballfoot_midpoint: 631,
               bboxes: {
                  ankle: {
                     bbox: [304, 1438, 441, 1571],
                     confidence: 0.898358941078186,
                  },
                  archfoot: {
                     bbox: [372, 1770, 768, 1822],
                     confidence: 0.9200687408447266,
                  },
                  ballfoot: {
                     bbox: [769, 1655, 889, 1829],
                     confidence: 0.9428620338439941,
                  },
                  foot: {
                     bbox: [198, 1499, 1080, 1827],
                     confidence: 0.9750052690505981,
                  },
               },
               dorsumHeightHalfFoot: 295,
               footLength: 882,
               timestamp: 1696963010.9388406,
               truncatedFootLength: 631,
               version: '4.0.0',
            },
            rightArch: {
               archEnd: 567,
               archHeight: 49,
               archHeightIndex: 0.48171701112877585,
               archStart: 196,
               archStartYolo: 192,
               ballfoot_midpoint: 228,
               bboxes: {
                  ankle: {
                     bbox: [739, 1362, 875, 1475],
                     confidence: 0.9075130224227905,
                  },
                  archfoot: {
                     bbox: [436, 1688, 807, 1737],
                     confidence: 0.9214714765548706,
                  },
                  ballfoot: {
                     bbox: [315, 1577, 434, 1739],
                     confidence: 0.9447809457778931,
                  },
                  foot: {
                     bbox: [146, 1411, 1003, 1742],
                     confidence: 0.9780656695365906,
                  },
               },
               dorsumHeightHalfFoot: 303,
               footLength: 857,
               timestamp: 1696963017.700975,
               truncatedFootLength: 629,
               version: '4.0.0',
            },
         },
         manualMeasure: {
            leftArch: {
               archEnd: 568,
               archHeightIndex: '0.51735',
               archStart: 172,
               bboxes: {
                  ankle: {
                     bbox: [304, 1438, 441, 1571],
                  },
                  archfoot: {
                     bbox: [300, 1710, 696, 1762],
                  },
                  ballfoot: {
                     bbox: [702, 1600, 822, 1774],
                  },
                  foot: {
                     bbox: [128, 1459, 1010, 1787],
                  },
               },
               footLength: 882,
               truncatedFootLength: 634,
            },
            rightArch: {
               archEnd: 567,
               archHeightIndex: '0.48846',
               archStart: 196,
               bboxes: {
                  ankle: {
                     bbox: [739, 1362, 875, 1475],
                  },
                  archfoot: {
                     bbox: [436, 1688, 807, 1737],
                  },
                  ballfoot: {
                     bbox: [315, 1577, 434, 1739],
                  },
                  foot: {
                     bbox: [146, 1434, 1003, 1741],
                  },
               },
               footLength: 857,
               truncatedFootLength: 628.5,
            },
         },
      },
      footOrientation: '',
      footScanEmailID: '',
      goals: ['Be less tired', 'Be more comfortable'],
      heelWearPattern: '',
      height: '6\' 0"',
      id: `TEST${userIndex}`,
      machineMeasured: true,
      manuallyMeasured: true,
      mlTimestamp: 1696963017.8426168,
      orderNumber: userIndex,
      pTimeStamp: 1700800584.049937,
      partner: true,
      pastInjuries: '',
      paymentEmailID: '',
      shipping_address: {
         address1: `123 TEST${userIndex} Street`,
         city: 'Shippington',
         company: 'Shipping Company',
         country: 'United States',
         country_code: 'US',
         first_name: 'Steve',
         last_name: 'Shipper',
         name: 'Steve Shipper',
         phone: '555-555-SHIP',
         province: 'Kentucky',
         province_code: 'KY',
         zip: '40003',
      },
      shoeType: 'We might want this later',
      showInDashboard: true,
      stlQueued: false,
      trackingID: 'Need to set this up',
      usMensShoeSize: "11.5 Men's USA",
      userID: '1x1sgEkVO0RanPhFD1oLaDRYZSJ2',
      weight: '180 lbs',
      wideFoot: 'No',
   }
}
