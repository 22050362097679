import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY_LOGGING_ENABLED.toLowerCase() === 'true') {
  Sentry.init({
    dsn: "https://8c7ed52450614155a9896e19fc355216@o4504645697011712.ingest.sentry.io/4504925363175424",
    tracesSampleRate: 0.0,
  })
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // React Strict mode must be disabled until firebaseUI is updated to work with React 18
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
