import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Container, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {observer} from 'mobx-react-lite';
import {submitMeasure} from '../../lib/process-data'
import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    green: {
      main: '#00b894',
      contrastText: '#fff',
    },
    blue: {
      main: '#0971f1',
      contrastText: '#fff',
      darker: '#053e85',
    },
    red: {
      main: '#e53e3e',
      contrastText: '#fff',
    },
    orange: {
      main: '#ff851b',
      contrastText: '#fff',
    }
  },
});


const NavigationButtons = observer(({database}) => {
  const navigate = useNavigate();

  const currPos = database.measure.position
  const hasNext = currPos < database.measure.positions.length - 1
  const isReset = database.measure.isReset[currPos]
  const hasPrev = currPos > 0

  return (
    <React.Fragment>
      <Container>
      <ThemeProvider theme={theme}>
      <Grid container direction="row"  justifyContent="space-around" alignItems="center">
        <Grid item>
          <Button onClick={() => (database.setFlagged(!database.measure.flagged))} color="red" variant={database.measure.flagged ? "contained" : "outlined"}>
            Flag
          </Button>
        </Grid>
        <Grid item>
          <Button color="blue" onClick={() => (database.decrementPosition())} disabled={!hasPrev} variant="contained">
            Previous
          </Button>
        </Grid>
        {hasNext ? <Grid item>
          <Button color="blue" onClick={() => (database.incrementPosition())} disabled={isReset} variant={hasNext ? "contained": "outlined"}>
            Next
          </Button>
        </Grid> : 
        <Grid item>
          <Button color="green" onClick={() => {
              submitMeasure()
              navigate('/dashboard')
              }} disabled={isReset} variant="contained">
            Submit
          </Button>
        </Grid>}
        <Grid item>
          <Button color="orange" variant="contained" onClick={() => {
              database.resetState()
              navigate('/dashboard')
              }}>
            Home
          </Button>
        </Grid>
      </Grid>
      </ThemeProvider>
      </Container>
    </React.Fragment>
  );
})

export default NavigationButtons
