// export const downloadImage = async (imageSrc) => {
//    const image = await fetch(imageSrc)
//    const imageBlog = await image.blob()
//    return imageBlog
// }

export const send2Backend = async (image, userID) => {
   // Production Server
   const serverURL = 'https://api.instryde.com'
   console.log('Sending FPI5 @ instrydeBackend')

   const endpointUrl = serverURL + '/fpi/5'
   const formData = formDataGenerator(image, userID)
   let response_output = null
   try {
      const response = await fetch(endpointUrl, {
         method: 'POST',
         body: formData,
      })
      if (!response.ok) {
         const text = await response.text()
         throw Error(text)
      }
      if (response.status === 200) {
         console.log(response.headers)
         const headers = response.headers
         response_output = { success: true, headers: headers }
         console.log('Successful Response')
      } else {
         response_output = { success: false }
         console.log("ERROR - Could not get a successful response. Either a bad photo or the server can't be reached.")
      }
   } catch (e) {
      console.log(e)
      return { success: false }
   }

   const output = { response: response_output }
   return output.response
}

const formDataGenerator = (image, userID) => {
   let body = new FormData()
   body.append('file', image)
   body.append('userId', userID)
   return body
}
