import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Container, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {observer} from 'mobx-react-lite';


const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    green: {
      main: '#00b894',
      contrastText: '#fff',
    },
    blue: {
      main: '#0971f1',
      contrastText: '#fff',
      darker: '#053e85',
    },
    red: {
      main: '#e53e3e',
      contrastText: '#fff',
    },
    yellow: {
      main: '#ffd600',
      contrastText: '#fff',
    }
  },
});


const BoundingBoxButtons = observer(({database}) => {
  const currPos = database.measure.position;
  const showFoot = database.measure.bboxes[currPos]['foot'].show;
  const showArch = database.measure.bboxes[currPos]['archfoot'].show;
  const showBall = database.measure.bboxes[currPos]['ballfoot'].show;

  return (
    <React.Fragment>
      <Container>
      <ThemeProvider theme={theme}>
      <Typography align="center" component="h2" variant="h6" color="primary" gutterBottom>
      Box Selection
      </Typography>
      <Grid container direction="row"  justifyContent="space-between" alignItems="center" sx={{mt: 3}}>
        <Grid item>
          <Button onClick={() => {database.toggleShowBbox('foot')}} sx={{mb: 1, width: '72.48px'}} color="red" variant={showFoot ? "contained" : "outlined"}>
            Foot
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => {database.toggleShowBbox('archfoot')}} sx={{mb: 1, width: '72.48px'}} color="green" variant={showArch ? "contained" : "outlined"}>
            Arch
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => {database.toggleShowBbox('ballfoot')}} sx={{mb: 1, width: '72.48px'}} color="blue" variant={showBall ? "contained" : "outlined"}>
            Ball
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => {database.setBboxesToML(null)}} sx={{mb: 1, width: '72.48px'}} color="yellow" variant={database.measure.isReset[database.measure.position] ? "outlined" : "contained"}>
            Reset
          </Button>
        </Grid>
      </Grid>
      </ThemeProvider>
      </Container>
    </React.Fragment>
  );
})

export default BoundingBoxButtons
