import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


export default function BoundingBoxPanel({user, position}) {

  return (
    <React.Fragment>
      <Typography align="center" component="h2" variant="h6" color="primary" gutterBottom>
      Bounding Box Confidence
      </Typography>
      <Grid container direction="row"  justifyContent="space-between" alignItems="center">
        <Grid item zeroMinWidth>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Foot
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {Math.round(user.footMeasurements.machineLearning[position].bboxes.foot.confidence * 100)}%
            </Typography>
        </Grid>
        <Grid item zeroMinWidth>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Arch
            </Typography>
            <Typography noWrap variant="h5" component="div">
            {Math.round(user.footMeasurements.machineLearning[position].bboxes.archfoot.confidence * 100)}%
            </Typography>
        </Grid>
        <Grid item zeroMinWidth>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Ball
            </Typography>
            <Typography noWrap variant="h5" component="div">
            {Math.round(user.footMeasurements.machineLearning[position].bboxes.ballfoot.confidence * 100)}%
            </Typography>
        </Grid>
        <Grid item zeroMinWidth>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Ankle
            </Typography>
            <Typography noWrap variant="h5" component="div">
            {Math.round(user.footMeasurements.machineLearning[position].bboxes.ankle.confidence * 100)}%
            </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
