import { ordersDataHold } from './orders-data-hold'
import { onValue, ref, query, orderByChild, equalTo, get } from 'firebase/database'
import { listAll, ref as sref } from 'firebase/storage'
import { db, stlDB, userImagesBucket } from '../../utils/firebase'

// main runner function for pulling order data
export const processOrders = () => {
   // Only retrieve users with a machine measurement
   let stlUnprocessedNumRef = query(ref(stlDB))
   onValue(stlUnprocessedNumRef, (snapshot) => {
      const data = snapshot.val()
      ordersDataHold.setRhinoPendingNum(data.numPendingRhino)
      ordersDataHold.setStlUnprocessedNum(data.numInStorage)
   })
   let usersRef = query(ref(db, 'Users/'), orderByChild('showInDashboard'), equalTo(true))
   onValue(usersRef, async (snapshot) => {
      let finalReview = []
      let readyForManualMeasure = []
      let noScans = []
      let usersWithoutFootMeasurement = []
      let usersWithoutPurchased = []
      const users = snapshot.val()
      console.log('data', users)

      if (users == null) {
         ordersDataHold.setUsersLists(
            finalReview,
            readyForManualMeasure,
            noScans,
            usersWithoutFootMeasurement,
            usersWithoutPurchased
         )
         return null
      }

      const userImagesDBRef = sref(userImagesBucket)
      const userImageRef = await listAll(userImagesDBRef)

      for (const user of Object.values(users)) {
         // If there is not a purchase tag for the session we need to check if there is one for the session
         let orderNumber = null
         try {
            // use to be ----------------> orderNumber =  (await get(ref(db, 'purchases/' + user.userID + '/orderNumber'))).val()
            orderNumber = (await get(ref(db, 'purchases/' + user.id + '/orderNumber'))).val()
            user.orderNumber = orderNumber
         } catch {
            orderNumber = null
         }
         // If user has "purchased"
         if (orderNumber) {
            if (user.manuallyMeasured) {
               if (!user.stlQueued) {
                  finalReview.push(user)
               }
            } else {
               const userImagesExist =
                  userImageRef.prefixes.filter((folder) => folder.fullPath === user.id).length > 0 ? true : false

               if (userImagesExist) {
                  user.footMeasurements
                     ? user.footMeasurements.machineLearning.leftArch && user.footMeasurements.machineLearning.rightArch
                        ? readyForManualMeasure.push(user)
                        : usersWithoutFootMeasurement.push(user)
                     : usersWithoutFootMeasurement.push(user)
               } else {
                  noScans.push(user)
               }
            }
         } else {
            usersWithoutPurchased.push(user)
         }
      }
      // Sort by last placed
      finalReview.sort((a, b) => a.timeStamp - b.timeStamp || a.partnerName - b.partnerName)
      readyForManualMeasure.sort((a, b) => a.timeStamp - b.timeStamp || a.partnerName - b.partnerName)
      noScans.sort((a, b) => a.timeStamp - b.timeStamp || a.partnerName - b.partnerName)
      usersWithoutFootMeasurement.sort((a, b) => a.timeStamp - b.timeStamp || a.partnerName - b.partnerName)
      usersWithoutPurchased.sort((a, b) => a.timeStamp - b.timeStamp)

      ordersDataHold.setUsersLists(
         finalReview,
         readyForManualMeasure,
         noScans,
         usersWithoutFootMeasurement,
         usersWithoutPurchased
      )
   })

   let requeUsersRef = query(ref(db, 'Users/'), orderByChild('stlQueued'), equalTo(true))
   onValue(requeUsersRef, async (snapshot) => {
      let usersToReQue = []
      const users = snapshot.val()
      console.log('data', users)

      for (const user of Object.values(users)) {
         const userDate = user.stlQueuedDate ?? Date.now() + 1000 * 3600 * 25
         const differenceInDays = (Date.now() - userDate) / (1000 * 3600 * 24)
         if (differenceInDays < 3) {
            usersToReQue.push(user)
         }
      }

      usersToReQue.sort((a, b) => a.timeStamp - b.timeStamp || a.partnerName - b.partnerName)

      ordersDataHold.setUsersReQueList(usersToReQue)
   })

   let manufactoringEmailQueuedRef = query(ref(db, 'Users/'), orderByChild('stlDownloaded'), equalTo(true))
   onValue(manufactoringEmailQueuedRef, async (snapshot) => {
      let manufactoringEmailQueued = []
      const users = snapshot.val()
      console.log('data', users)

      for (const user of Object.values(users)) {
         if (user.manufactoringEmailQueued === true) {
            manufactoringEmailQueued.push(user)
         }
      }

      manufactoringEmailQueued.sort((a, b) => a.timeStamp - b.timeStamp || a.partnerName - b.partnerName)

      ordersDataHold.setManufactoringEmailQueued(manufactoringEmailQueued)
   })
}
