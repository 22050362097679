import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppBar from '../components/AppBar';
import pullFirebaseData, { analyticsDataHold } from '../lib/process-data';
import InfoPanel from './InfoPanel';
import Table from './Table';


const mdTheme = createTheme();

const DashboardContent = observer(({database}) => {

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    pullFirebaseData('session', database, params.sessionID);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (database.session.validID) {
    return (
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar title="inStryde ML Analytics Dashboard" />
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  {/* Chart */}
                  <Grid item xs={12}>
                    <Paper style={{height: "100%", width: "100%"}}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 100,
                      }}
                    >
                        {database.session.sessionID != null ? 
                            <InfoPanel 
                                sessionID={database.session.sessionID} 
                                version={database.session.fpiVersion} 
                                date={database.session.date} /> : <div>Loading...</div>}
                    </Paper>
                  </Grid>
                  {/* Tables and Photos */}
                  {/* Left Arch */}
                  <Grid item xs={12} md={9.25}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {database.session.leftArch != null ? <Table data={database.session.leftArch} title={"Left Arch"} /> : <div>Loading...</div>}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center"  }}>
                    {database.session.leftArchPhoto != null ? <img style={{width:"209.896875px", height:"373.15px"}} src={database.session.leftArchPhoto} alt="Left Arch"></img> : null}
                    </Paper>
                  </Grid>
                  {/* Right Arch */}
                  <Grid item xs={12} md={9.25}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {database.session.rightArch != null ? <Table data={database.session.rightArch} title={"Right Arch"} /> : <div>Loading...</div>}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center"  }}>
                    {database.session.rightArchPhoto != null ? <img style={{objectFit: "contain", width:"209.896875px"}} src={database.session.rightArchPhoto} alt="Right Arch"></img> : null}
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      );
    } else {
    return (
        <div style={{backgroundColor: '#21232a', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 20px'}}>
          <h1 style={{fontSize: '36px', color: '#fff', fontWeight: '500', lineHeight: '1'}}>Invalid SessionID <p style={{fontSize: '68%', fontWeight: '400', lineHeight: '1', color: '#777', display: 'inline'}}>404</p></h1>
          <p style={{fontSize: '21px', color: 'silver', lineHeight: '1.4'}}>Fresh sessionID? Wait a few seconds for it to populate in the database. Otherwise, the sessionID does not exist.</p>
          <TextField 
            autoFocus
            label="SessionID Lookup" 
            defaultValue={database.session.sessionID}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                  pullFirebaseData('session', database, e.target.value);
                  navigate(`/ml-analytics/session/${e.target.value}`, {sessionIDChange: e.target.value})
              }
          }}
            sx={{ width: '32ch', 
            input: { color: 'white' } }} />
          </div>
        </div>
    )
  }
})

const SessionDashboard = () => {
    return (<DashboardContent database={analyticsDataHold}/>)
  }

export default SessionDashboard;
