import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import Title from '../components/Title';
import styles from "../css/Table.module.css";
import { Link } from "react-router-dom";


export default function Orders({data, title}) {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Table component='div' size="small">
        <TableHead component='div'>
          <TableRow component='div'>
            <TableCell component='div'>Parameter Name</TableCell>
            <TableCell component='div' align="right">Machine Predicted</TableCell>
            <TableCell component='div' align="right">Manual Measured</TableCell>
            <TableCell component='div' align="right">Average Percent Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component='div'>
          {data.map((data) => (
            <TableRow className={styles.tableRow} component={Link} to={`/ml-analytics/parameter/${data.parameterName}`} key={data.parameterName}>
              <TableCell component='div'>{data.parameterName}</TableCell>
              <TableCell component='div' align="right">{data.machinePredicted}</TableCell>
              <TableCell component='div' align="right">{data.manualMeasured}</TableCell>
              <TableCell component='div' align="right">{`${data.pctError}%`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
