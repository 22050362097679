import * as React from 'react';
import Title from '../components/Title';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';


export default function Chart({sessionID, version, date}) {

  return (
    <React.Fragment>
      <Title>Session</Title>
      <Grid container direction="row"  justifyContent="space-between" alignItems="center">
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Session ID
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {sessionID}
            </Typography>
          </Container>
        </Grid>
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Version
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {version}
            </Typography>
          </Container>
        </Grid>
        <Grid item zeroMinWidth>
          <Container>
            <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              ML Capture Date
            </Typography>
            <Typography noWrap variant="h5" component="div">
              {date}
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
