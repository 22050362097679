import { onValue, ref, query, orderByChild, equalTo } from 'firebase/database'
import { getDownloadURL, ref as sref } from 'firebase/storage'
import { makeAutoObservable } from 'mobx'
import { db, userImagesBucket } from '../../utils/firebase'

const parameters = ['archEnd', 'archStart', 'archHeightIndex', 'footLength', 'truncatedFootLength']

export class AnalyticsDataHold {
   constructor() {
      makeAutoObservable(this)
   }

   setData(page, data) {
      if (page === 'overview') {
         this.overview.chartData = data.chart
         this.overview.tableData = data.table
      } else if (page === 'parameter') {
         this.parameter.name = data.parameter
         this.parameter.chartData = data.chart
         this.parameter.tableData = data.table
      } else if (page === 'session') {
         this.session = data
      }
   }

   setLeftArchPhoto(url) {
      this.session.leftArchPhoto = url
   }

   setRightArchPhoto(url) {
      this.session.rightArchPhoto = url
   }

   overview = {
      chartData: null,
      tableData: null,
   }
   parameter = {
      chartData: null,
      tableData: null,
      name: null,
   }
   session = {
      validID: true,
      sessionID: null,
      leftArch: null,
      rightArch: null,
      fpiVersion: null,
      date: null,
      leftArchPhoto: null,
      rightArchPhoto: null,
   }
}

// This is the function that we will use when we want to import the store
export let analyticsDataHold = new AnalyticsDataHold()

const pullFirebaseData = (page, store, key) => {
   console.log('Pulling firebase data...')

   const userDBRef = query(ref(db, 'Users/'), orderByChild('manuallyMeasured'), equalTo(true))
   onValue(userDBRef, (snapshot) => {
      const data = snapshot.val()

      if (page === 'overview') {
         const overviewData = getOverview(data)
         const overviewChart = grabOverviewChartData(overviewData)
         store.setData('overview', { table: overviewData, chart: overviewChart })
      } else if (page === 'parameter') {
         const parameterData = grabParameter(data, key)
         const parameterChart = grabParameterChartData(parameterData)
         store.setData('parameter', { parameter: key, table: parameterData, chart: parameterChart })
      } else if (page === 'session') {
         if (validateSessionID(data, key)) {
            const leftArchPhotoRef = sref(userImagesBucket, `/${key}/LeftArch.png`)
            getDownloadURL(leftArchPhotoRef).then((url) => {
               store.setLeftArchPhoto(url)
            })
            const rightArchPhotoRef = sref(userImagesBucket, `/${key}/RightArch.png`)
            getDownloadURL(rightArchPhotoRef).then((url) => {
               store.setRightArchPhoto(url)
            })

            const timestamp = data[key].footMeasurements.machineLearning.leftArch.timestamp
            const date = new Date(Math.abs(timestamp * 1000)).toDateString()
            store.setData('session', {
               sessionID: key,
               validID: true,
               leftArch: grabSession(data, key, 'leftArch'),
               rightArch: grabSession(data, key, 'rightArch'),
               fpiVersion: data[key].footMeasurements.machineLearning.leftArch.version,
               date: date,
            })
         } else {
            store.setData('session', {
               sessionID: key,
               validID: false,
               leftArch: null,
               rightArch: null,
               fpiVersion: null,
               date: null,
               leftArchPhoto: null,
               rightArchPhoto: null,
            })
         }
      }
   })
}
export default pullFirebaseData

// Overview Page - Table
const getOverview = (data) => {
   // semver.valid(lastVersion)
   const processedData = []
   for (const parameter of parameters) {
      try {
         const targetInfo = grabParameter(data, parameter)
         // Add up all the values
         const leftArchPctError = targetInfo.reduce((acc, curr) => acc + curr.pctError, 0)
         const rightArchPctError = targetInfo.reduce((acc, curr) => acc + curr.pctError, 0)
         // Divide by the number of values
         const leftArchPctErrorAvg = leftArchPctError / targetInfo.length
         const rightArchPctErrorAvg = rightArchPctError / targetInfo.length
         const pctErrorAvgRounded = Math.round((leftArchPctErrorAvg + rightArchPctErrorAvg) / 2)

         processedData.push({ targetName: parameter, pctError: pctErrorAvgRounded })
      } catch {
         // If the key could not be accessed we don't want to add it to the processedData array
         // This is because either the manual measurement or machine measurement is not in the firebase database
         continue
      }
   }
   // Sort array by Average Pct Error
   return processedData.sort((a, b) => b.pctError - a.pctError)
}

// Overview Page - Chart
const grabOverviewChartData = (data) => {
   // Create an empty array with 10 values
   const chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
   for (const row of data) {
      // If the pctError is not a number, don't add it to the chart
      if (!isNaN(row.pctError)) {
         const index = Math.floor(row.pctError / 10)
         if (index < 10) {
            chartData[index] += 1
         } else {
            chartData[9] += 1
         }
      }
   }
   return chartData
}

// Parameter Page - Table
const grabParameter = (data, parameter) => {
   // [UUID, machinePredicted, manualMeasured, pctDiff]
   let processedData = []
   for (const user of Object.entries(data)) {
      const uuid = user[0]
      const userData = user[1]
      try {
         const machinePredictedLeft = userData.footMeasurements.machineLearning['leftArch'][parameter]
         const machinePredictedRight = userData.footMeasurements.machineLearning['rightArch'][parameter]
         const machinePredicted = (machinePredictedLeft + machinePredictedRight) / 2

         let manualMeasured = null
         let pctError = null
         try {
            const manualMeasuredLeft = parseFloat(userData.footMeasurements.manualMeasure['leftArch'][parameter])
            const manualMeasuredRight = parseFloat(userData.footMeasurements.manualMeasure['rightArch'][parameter])
            manualMeasured = (manualMeasuredLeft + manualMeasuredRight) / 2

            pctError = Math.round((Math.abs(machinePredicted - manualMeasured) / manualMeasured) * 100)
         } catch {
            // If the key could not be accessed we want to still add the manual results to the processedData array
            manualMeasured = 'Not Available'
            pctError = NaN
         }

         processedData.push({
            sessionID: uuid,
            machinePredicted: machinePredicted,
            manualMeasured: manualMeasured,
            pctError: pctError,
         })
      } catch {
         // If the key could not be accessed we don't want to add it to the processedData array
         // This is because both the manual measurement or machine measurement is not in the firebase database
         continue
      }
   }

   return processedData.sort((a, b) => b.pctError - a.pctError)
}

// Parameter Page - Chart
const grabParameterChartData = (data) => {
   // Create an empty array with 10 values
   const chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
   for (const row of data) {
      if (!isNaN(row.pctError)) {
         const index = Math.floor(row.pctError / 10)
         if (index < 10) {
            chartData[index] += 1
         } else {
            chartData[9] += 1
         }
      }
   }
   return chartData
}

// Session Page - Verification
const validateSessionID = (data, sessionID) => {
   let validID = false
   for (const user of Object.keys(data)) {
      if (user === sessionID) {
         validID = true
         break
      }
   }
   return validID
}

// Session Page - Session 1 - 5
const grabSession = (data, sessionID, position) => {
   let sessionInfo = data[sessionID]
   let processedData = []
   for (const parameter of Object.keys(data[sessionID].footMeasurements.machineLearning[position])) {
      if (parameters.indexOf(parameter) === -1) {
         continue
      }
      const machinePredicted = sessionInfo.footMeasurements.machineLearning[position][parameter]

      let manualMeasured = null
      let pctError = null
      try {
         manualMeasured = sessionInfo.footMeasurements.manualMeasure[position][parameter]
         pctError = Math.round((Math.abs(machinePredicted - manualMeasured) / manualMeasured) * 100)
      } catch {
         // If the key could not be accessed we want to still add the manual results to the processedData array
         manualMeasured = 'Not Available'
         pctError = NaN
      }
      processedData.push({
         parameterName: parameter,
         machinePredicted: machinePredicted,
         manualMeasured: manualMeasured,
         pctError: pctError,
      })
   }

   return processedData.sort((a, b) => b.pctError - a.pctError)
}
