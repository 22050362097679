import * as React from 'react'
import { observer } from 'mobx-react-lite'
import LoadingButton from '@mui/lab/LoadingButton'
import MemoryIcon from '@mui/icons-material/Memory'
import processingContainerStyles from '../css/processing-blocks/BlockContainer.module.css'
import titleStyles from '../css/processing-blocks/Title.module.css'
import counterStyles from '../css/processing-blocks/Counter.module.css'
import buttonStyles from '../css/processing-blocks/Button.module.css'
import { ordersDataHold } from '../lib/orders-data-hold'

const RhinoProcessPanel = ({ database }) => {
   const startRhinoGCP = () => {
      ordersDataHold.setRhinoSendingRequest(true)
      var formdata = new FormData()
      formdata.append('authKey', 'DBgfYzR9sJ39VjEy')

      fetch('https://api.instryde.com/start_rhino_gcp', { method: 'POST', body: formdata })
         .then((response) => response.text())
         .then((result) => {
            ordersDataHold.setRhinoSendingRequest(false)
            console.log(result)
         })
         .catch((error) => {
            ordersDataHold.setRhinoSendingRequest(false)
            alert('The rhino GCP service could not be started. Check console logs for more information.')
            console.log('error', error)
         })
   }
   return (
      <>
         <div className={processingContainerStyles.blockContainer}>
            <div className={titleStyles.title}>Rhino Processing</div>
            <div className={counterStyles.counter}>
               Pending STL Generation:
               <div className={counterStyles.counterNumber}>{database.rhinoManagement.numPendingRhino}</div>
            </div>
            <div className={buttonStyles.button}>
               <LoadingButton
                  variant="contained"
                  size="large"
                  disabled={!database.rhinoManagement.numPendingRhino}
                  loading={database.rhinoManagement.sendingRequest}
                  loadingPosition="end"
                  endIcon={<MemoryIcon />}
                  onClick={() => startRhinoGCP()}
               >
                  Process
               </LoadingButton>
            </div>
         </div>
      </>
   )
}

export default observer(RhinoProcessPanel)
