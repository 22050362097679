import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import AppBar from '../components/AppBar'
import RhinoProcessPanel from './RhinoProcessPanel'
import STLDownloadPanel from './STLDownloadPanel'
import { processOrders } from '../lib/process-orders'
import { ordersDataHold } from '../lib/orders-data-hold'
import TablePanel from './TablePanel'

const mdTheme = createTheme()

const ListContent = observer(({ database }) => {
   React.useEffect(() => {
      processOrders()
   }, []) // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <ThemeProvider theme={mdTheme}>
         <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar title="inStryde Dashboard" />
            <Box
               component="main"
               sx={{
                  backgroundColor: (theme) =>
                     theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
               }}
            >
               <Toolbar />
               <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', maxWidth: '1200px' }}>
                  <div>
                     {database.stlManagement.stlUnprocessedNum != null ? (
                        <STLDownloadPanel database={database} />
                     ) : null}
                  </div>
                  <div>
                     {database.stlManagement.stlUnprocessedNum != null ? (
                        <RhinoProcessPanel database={database} />
                     ) : null}
                  </div>
               </div>

               <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', maxWidth: '1200px' }}>
                  <TablePanel
                     database={database}
                     title={'Final Review'}
                     userList={database.usersLists.finalReview}
                     showToolbarButtons={true}
                  />
                  <TablePanel
                     database={database}
                     title={'Ready for Manual Measure'}
                     userList={database.usersLists.readyForManualMeasure}
                     showToolbarButtons={false}
                  />
                  <TablePanel
                     database={database}
                     title={'Manual Measure AI Issue'}
                     userList={database.usersLists.usersWithoutFootMeasurement}
                     showToolbarButtons={false}
                     rowsClickable={false}
                  />
                  <TablePanel
                     database={database}
                     title={'NO SCAN IMAGES'}
                     userList={database.usersLists.noScans}
                     showToolbarButtons={false}
                     rowsClickable={false}
                  />
                  <TablePanel
                     database={database}
                     title={'NO PURCHASE'}
                     userList={database.usersLists.usersWithoutPurchased}
                     showToolbarButtons={false}
                     rowsClickable={false}
                  />
                  <TablePanel
                     database={database}
                     title={'Re-Que'}
                     userList={database.usersToReQue}
                     showToolbarButtons={false}
                     rowsClickable={false}
                  />
                  <TablePanel
                     database={database}
                     title={'Send Manufacturing Email'}
                     userList={database.usersToQueManufactoringEmail}
                     showToolbarButtons={false}
                     rowsClickable={false}
                  />
               </div>
            </Box>
         </Box>
      </ThemeProvider>
   )
})

const UserList = () => {
   return <ListContent database={ordersDataHold} />
}

export default UserList
