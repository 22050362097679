import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import Title from '../../components/Title';
import { observer } from 'mobx-react-lite';


const ParameterInfo = observer(({data}) => {

  const measurementUnit = data.measurementUnit[data.position];

  return (
    <React.Fragment>
      <Title>Parameter Info</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Parameter Name</TableCell>
            <TableCell align="right">Machine Predicted ({measurementUnit})</TableCell>
            <TableCell align="right">Manual Measured ({measurementUnit})</TableCell>
            <TableCell align="right">Average Percent Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.paramData[data.position]?.map((data) => (
            <TableRow key={data.parameterName}>
              <TableCell>{data.parameterName}</TableCell>
              <TableCell align="right">{data.machinePredicted}</TableCell>
              <TableCell align="right">{data.manualMeasured}</TableCell>
              <TableCell align="right">{`${data.pctError}%`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
})

export default ParameterInfo;
