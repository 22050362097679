import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import { onValue, ref } from 'firebase/database'
import { db } from './utils/firebase'
import 'firebase/compat/auth'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import './App.css'
import { observer } from 'mobx-react-lite'

import { ordersDataHold } from './dashboard/lib/orders-data-hold'

// ML Analytics
import AvgPerfDashboard from './ml-analytics/overview/OverviewDashboard'
import ParamDashboard from './ml-analytics/param-performance/ParamDashboard'
import SessionDashboard from './ml-analytics/session-performance/SessionDashboard'

// Manual Measure Screen
import UserList from './dashboard/user-list/UserList'
import Measure from './dashboard/measure/Measure'

// Configure FirebaseUI
const uiConfig = {
   // Popup signin flow rather than redirect flow.
   signInFlow: 'popup',
   // We will display Google as auth providers.
   signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
   callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
   },
}

const App = () => {
   const [subText, setSubText] = useState('Please sign-in:')

   // Listen to the Firebase Auth state and set the local state.
   useEffect(() => {
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
         // On user login add new listener.
         if (user) {
            // Check if refresh is required.
            const metadataRef = ref(db, 'metadata/' + user.uid + '/refreshTime')
            onValue(metadataRef, (snapshot) => {
               // Force refresh to pick up the latest custom claims changes.
               // Note this is always triggered on first call. Further optimization could be
               // added to avoid the initial trigger when the token is issued and already contains
               // the latest claims.
               user
                  .getIdTokenResult(true)
                  .then(function (idTokenResult) {
                     console.log('Refreshed login token')
                     ordersDataHold.setSignedIn(true)
                     if (!!idTokenResult.claims.admin) {
                        console.log('authed as admin')
                        ordersDataHold.setAuthed(true)
                     } else {
                        setSubText('Please sign-in as an admin')
                        ordersDataHold.setAuthed(false)
                     }
                  })
                  .catch(function (error) {
                     console.log('Error fetching refreshed token:', error)
                  })

               // console.log("ran")
               // firebase.auth().currentUser.getIdTokenResult().then(function(idTokenResult) {
               //   // Confirm the user is an Admin.
               //   console.log(idTokenResult.claims)
               //   if (!!idTokenResult.claims.admin) {
               //     setIsSignedIn(!!user);
               //   } else {
               //     setSubText('Please sign-in as an admin');
               //     setIsSignedIn(false);
               //   }
               // }).catch(function(error) {
               //   console.log(error)
               // })
            })
         }
      })
      return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
   }, [])

   if (!ordersDataHold.auth.authed) {
      return (
         <div className="App-header">
            <h1>Welcome to the inStryde Dashboard</h1>
            <p>{subText}</p>
            {!ordersDataHold.auth.signedIn ? (
               <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            ) : (
               <Button className="button" variant="contained" onClick={() => ordersDataHold.signOut()}>
                  Sign Out
               </Button>
            )}
         </div>
      )
   }
   return (
      <Router>
         <Routes>
            <Route path="/dashboard" element={<UserList />} />
            <Route path="/dashboard/measure/:sessionID" element={<Measure />} />

            <Route path="/ml-analytics" element={<AvgPerfDashboard />} />
            <Route path="/ml-analytics/parameter/:parameter" element={<ParamDashboard />} />
            <Route path="/ml-analytics/session/:sessionID" element={<SessionDashboard />} />
            <Route path="/" element={<Home />} />
         </Routes>
      </Router>
   )
}

function Home() {
   return (
      <div className="App-header">
         <h1>inStryde Analytics</h1>
         <div className="Button-container">
            <Button className="button" variant="contained" component={Link} to={'/dashboard'}>
               Dashboard
            </Button>
            <Button className="button" variant="contained" component={Link} to={'/ml-analytics'}>
               ML Analytics
            </Button>
         </div>
      </div>
   )
}

export default observer(App)
