import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import * as Rnd from 'react-rnd';

const FootBox = ({color}) => (
    <div
      className="box"
      style={{ margin: 0, border: `2px solid ${color}`, height: '100%', opacity: 0.75, alignItems: 'center', justifyContent: 'center'}}
    >
    </div>
);

let imageSize = {width: null, height: null};

const ImagePanel = observer(({database}) => {
    const image = useRef(null)
    
    const bboxes = database.measure.bboxes[database.measure.position];



    const onResize = () => {
        if (imageSize.width === null || imageSize.height === null || database.measure.initialLoad[database.measure.position]) {
            imageSize = {width: image.current.width, height: image.current.height}
        }

        let [previousImageWidth, previousImageHeight] = [imageSize.width, imageSize.height];
        imageSize = {width: image.current.width, height: image.current.height}
        database.updateImgSize(imageSize);

        if (database.measure.initialLoad[database.measure.position]) {
            console.log("resetting params")
            database.updateOnResize(previousImageWidth, previousImageHeight, image.current.naturalWidth, image.current.naturalHeight);
            database.setNatualImageSize(image.current.naturalWidth, image.current.naturalHeight);
            database.setBboxesToML(null, previousImageWidth, previousImageHeight, image.current.naturalWidth, image.current.naturalHeight);
            database.setInitialLoadFalse(database.measure.position);
        }
        database.updateOnResize(previousImageWidth, previousImageHeight, image.current.naturalWidth, image.current.naturalHeight);
    }

    const queueOnResize = () => {
        if (image.current) {
            if (image.current.complete) {
                setTimeout(() => {
                    onResize()
                }, 500);
              } else {
                image.current.addEventListener('load', () => {
                    setTimeout(() => {
                        onResize()
                    }, 500);
                })
                image.current.addEventListener('error', function() {
                    alert('error')
                })
            }
        }
    }
    
      useEffect(() => {
        // We need a resize event listener so that the bounding box is moved to the 
        // correct position whenever the window is resized
        console.log("ran")
        // Run initally to set the bounding box according to the img size
        queueOnResize()
        window.addEventListener('resize', queueOnResize);
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
        <img id="img" ref={image} style={{ width: "100%" }} draggable="false" src={database.measure.photos[database.measure.position]} alt="Left Arch"></img>
        {bboxes.foot.show ? 
            <Rnd.Rnd style={{alignItems: 'center', justifyContent: 'center'}}
                position={{x: bboxes.foot.pos.x, y: bboxes.foot.pos.y}}
                size={{width: bboxes.foot.size.width, height: bboxes.foot.size.height}}
                minWidth={5}
                minHeight={5}
                bounds='img'
                onDragStop={(e, d) => {
                    database.setReset(false)
                    database.setBbox({pos:{x: d.x, y: d.y}, size: {width: bboxes.foot.size.width, height: bboxes.foot.size.height}}, 'foot')
                    database.computeParams()
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    database.setReset(false)
                    database.setBbox({pos: {x: position.x, y: position.y}, size: {width: ref.offsetWidth, height: ref.offsetHeight}}, 'foot')
                    database.computeParams()
                }}
            >
            <FootBox color="red"/>
            <div className='box' style={{ height: '100%', width: 3, opacity: 1.0, backgroundColor: 'darkred', position: 'absolute', left: bboxes.foot.size.width/2 - 1, bottom: '0%' }}></div>
        </Rnd.Rnd> : <></>}
        {bboxes.ballfoot.show ? 
            <Rnd.Rnd style={{alignItems: 'center', justifyContent: 'center'}}
                position={{x: bboxes.ballfoot.pos.x, y: bboxes.ballfoot.pos.y}}
                size={{width: bboxes.ballfoot.size.width, height: bboxes.ballfoot.size.height}}
                minWidth={5}
                minHeight={5}
                bounds='img'
                onDragStop={(e, d) => {
                    database.setReset(false)
                    database.setBbox({pos:{x: d.x, y: d.y}, size: {width: bboxes.ballfoot.size.width, height: bboxes.ballfoot.size.height}}, 'ballfoot')
                    database.computeParams()
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    database.setReset(false)
                    database.setBbox({pos: {x: position.x, y: position.y}, size: {width: ref.offsetWidth, height: ref.offsetHeight}}, 'ballfoot')
                    database.computeParams()
                }}
            >
            <FootBox color="blue"/>
        </Rnd.Rnd> : <></>}
        {bboxes.archfoot.show ? 
            <Rnd.Rnd style={{alignItems: 'center', justifyContent: 'center'}}
                position={{x: bboxes.archfoot.pos.x, y: bboxes.archfoot.pos.y}}
                size={{width: bboxes.archfoot.size.width, height: bboxes.archfoot.size.height}}
                minWidth={5}
                minHeight={5}
                bounds='img'
                onDragStop={(e, d) => {
                    database.setReset(false)
                    database.setBbox({pos:{x: d.x, y: d.y}, size: {width: bboxes.archfoot.size.width, height: bboxes.archfoot.size.height}}, 'archfoot')
                    database.computeParams()
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    database.setReset(false)
                    database.setBbox({pos: {x: position.x, y: position.y}, size: {width: ref.offsetWidth, height: ref.offsetHeight}}, 'archfoot')
                    database.computeParams()
                }}
            >
            <FootBox color="green"/>
        </Rnd.Rnd> : <></>}
    </>
  );
})


export default ImagePanel
