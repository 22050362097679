import firebase from 'firebase/compat/app';
// import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
   apiKey: 'AIzaSyDiI_ojWTd2zCPJ49hjuCPVrUwouAq99Q4',
   authDomain: 'instryde-pwa.firebaseapp.com',
   projectId: 'instryde-pwa',
   database: 'instryde-pwa-default-rtdb.firebaseio.com',
   storageBucket: 'instryde-pwa.appspot.com',
   messagingSenderId: '652629752063',
   appId: '1:652629752063:web:4d96bb2d3e7606391a34ca',
   measurementId: 'G-QJXQHBVPTV',
}

export const app = firebase.initializeApp(firebaseConfig)
export const db = getDatabase(app)
export const storage = getStorage(app)
export const userImagesBucket = getStorage(app, 'gs://instryde-pwa-user-images/')

export const stlDB = getDatabase(app, 'instryde-pwa-stl-queue.firebaseio.com')
export const stlQueueOutputBucket = getStorage(app, 'gs://instryde-pwa-stl-queue-output')
