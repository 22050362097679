import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ToolbarButtons from '../components/ToolbarButtons'
import tableContainerStyles from '../css/table-blocks/BlockContainer.module.css'
import titleStyles from '../css/table-blocks/Title.module.css'
import tableStyles from '../css/table-blocks/TableStyle.module.css'
import tableCellStyles from '../css/table-blocks/TableCell.module.css'
import toolbarStyles from '../css/table-blocks/ToolbarContainer.module.css'
import TableRow from './table-row'

export default function TableComponent({ data, title, showToolbarButtons, rowsClickable }) {
   const [isCheckAll, setIsCheckAll] = useState(false)
   // Handles row checkbox functionality
   const [rowsChecked, setRowsChecked] = useState([])
   // Contains row data to send to ToolbarButtons.js when uploading/deleting
   const [rowData, setRowData] = useState([])
   const [filteredRowData, setFilteredRowData] = useState([])
   const [hideSelectedColumns, setHideSelectedColumns] = useState([])

   let navigate = useNavigate()

   const handleRowClick = (sessionId) => {
      // Uses row.id for navigation
      if (rowsClickable !== false) {
         navigate(`/dashboard/measure/${sessionId}`)
      }
   }

   const handleRowCheckClick = (event, index) => {
      event.stopPropagation()
      const { id, checked } = event.target
      const selectedIndex = index

      console.log('this is the index that is being returned', selectedIndex)

      setRowsChecked([...rowsChecked, id])
      if (!checked) {
         setRowsChecked(rowsChecked.filter((item) => item !== id))
      }

      setRowData([...rowData, { index: selectedIndex, dataIndex: selectedIndex }])
      if (!checked) {
         setRowData(rowData.filter((item) => item.index !== selectedIndex))
      }
   }

   const handleCheckAllClick = (data) => {
      setIsCheckAll(!isCheckAll)
      setRowsChecked(data.map((item) => item.id))

      setRowData(data.map((item, index) => ({ index: index, dataIndex: index })))

      if (isCheckAll) {
         setRowsChecked([])
         setRowData([])
      }
   }

   const handleDidFilterRowData = (filteredRowData) => {
      console.log('handleDidFilterRowData called')
      setFilteredRowData(filteredRowData)
   }

   const handleSelectedColumns = (selectedColumn) => {
      setHideSelectedColumns([...hideSelectedColumns, selectedColumn])
      if (hideSelectedColumns.includes(selectedColumn)) {
         setHideSelectedColumns(hideSelectedColumns.filter((item) => item !== selectedColumn))
      }
   }

   return (
      <>
         <div className={tableContainerStyles.purchasedBlockContainer}>
            <div className={tableContainerStyles.blockTitleRow}>
               <div className={titleStyles.tableBlockTitle}>{title}</div>
               <div className={toolbarStyles.container}>
                  {rowsChecked.length !== 0 ? (
                     <div className={titleStyles.tableRowsSelected}>{rowsChecked.length} row(s) selected</div>
                  ) : (
                     <></>
                  )}
                  <ToolbarButtons
                     rowData={rowData}
                     rowsChecked={rowsChecked}
                     hideSelectedColumns={hideSelectedColumns}
                     callbackRowData={handleDidFilterRowData}
                     callbackHideSelectedColumns={handleSelectedColumns}
                     data={data}
                     showToolbarButtons={showToolbarButtons}
                     block={title}
                  />
               </div>
            </div>
            <div className={tableStyles.table}>
               <table style={{ width: '98%', margin: '10px', borderCollapse: 'collapse' }}>
                  <tbody>
                     <tr className={tableCellStyles.cellStyle}>
                        <th>
                           <input
                              type="checkbox"
                              onChange={() => handleCheckAllClick(data)}
                              checked={rowsChecked.length === data.length && data.length !== 0}
                           />
                        </th>
                        {!hideSelectedColumns.includes('id') && <th>Session ID</th>}
                        {!hideSelectedColumns.includes('orderNumber') && <th>Order Number</th>}
                        {!hideSelectedColumns.includes('protect3dCID') && <th>Protect3d CID</th>}
                        {!hideSelectedColumns.includes('firstName') && <th>First Name</th>}
                        {!hideSelectedColumns.includes('email') && <th>Email</th>}
                        {!hideSelectedColumns.includes('partnerName') && <th>Partner</th>}
                        {!hideSelectedColumns.includes('mlTimestamp') && (
                           <th data-testid="creationDateHeader">Creation Date</th>
                        )}
                     </tr>

                     {filteredRowData[0] === 'No matching records' && (
                        <tr className={tableStyles.tableRowHover}>
                           <td>No matching records</td>
                        </tr>
                     )}

                     {filteredRowData.length > 0
                        ? filteredRowData.map((row, index) => (
                             <TableRow
                                key={index}
                                index={index}
                                hideSelectedColumns={hideSelectedColumns}
                                row={row}
                                rowsChecked={rowsChecked}
                                handleRowClick={handleRowClick}
                                handleRowCheckClick={handleRowCheckClick}
                             />
                          ))
                        : data.map((row, index) => (
                             <TableRow
                                key={index}
                                index={index}
                                hideSelectedColumns={hideSelectedColumns}
                                row={row}
                                rowsChecked={rowsChecked}
                                handleRowClick={handleRowClick}
                                handleRowCheckClick={handleRowCheckClick}
                             />
                          ))}
                  </tbody>
               </table>
            </div>
         </div>
      </>
   )
}
