import React, { useState } from 'react'
import Button from './Button'
import { ordersDataHold } from '../lib/orders-data-hold'
import { ref, update, set, get } from 'firebase/database'
import { db, stlDB, userImagesBucket } from '../../utils/firebase'
import { deleteObject, getBlob, listAll, ref as sref } from 'firebase/storage'
import PublishIcon from '@mui/icons-material/Publish'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import SearchIcon from '@mui/icons-material/Search'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import RotatedLeft from '@mui/icons-material/RotateLeft'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import toolbarButtonStyles from '../css/table-blocks/ToolbarButtons.module.css'
import { send2Backend } from '../lib/send2backend'
import { Email, Fingerprint } from '@mui/icons-material'
import CollectionsIcon from '@mui/icons-material/Collections'
import JSZip from 'jszip'
import saveAs from 'file-saver'

export default function ToolbarButtons({
   rowData,
   rowsChecked,
   hideSelectedColumns,
   callbackRowData,
   callbackHideSelectedColumns,
   data,
   showToolbarButtons,
   block,
}) {
   const [showSearchBar, setShowSearchBar] = useState(false)
   const [showViewColumnSelector, setShowViewColumnSelector] = useState(false)
   const [selectedFilters, setSelectedFilters] = useState([])

   function PixelToInches(value, footLengthPixels, shoeSize) {
      const footLengthInches = 0.323 * shoeSize + 7.36

      return (value / footLengthPixels) * footLengthInches
   }

   const setSTLTrue = () => {
      const purchasedNotStlQueued = ordersDataHold.usersLists.finalReview
      for (const selectedUser of rowsChecked) {
         // rowData
         const index = purchasedNotStlQueued.map((row) => row.id).indexOf(selectedUser)
         let removedUser = purchasedNotStlQueued[index]
         let updates = {}
         updates['/Users/' + removedUser.id + '/stlQueued'] = true
         updates['Users/' + removedUser.id + '/stlQueuedDate'] = Date.now()
         updates['Users/' + removedUser.id + '/orderNumber'] = removedUser.orderNumber
         updates['purchases/' + removedUser.userID] = null
         updates['/Users/' + removedUser.id + '/showInDashboard'] = false
         update(ref(db), updates)
      }
   }

   const uploadToSTL = () => {
      const purchasedNotStlQueued = ordersDataHold.usersLists.finalReview
      console.log(purchasedNotStlQueued)
      for (const selectedUser of rowData) {
         let user = purchasedNotStlQueued[selectedUser.index] // selectedUser.dataIndex
         const leftFootLength = user.footMeasurements.manualMeasure['leftArch']['footLength']
         const rightFootLength = user.footMeasurements.manualMeasure['rightArch']['footLength']
         const shoeSize = user.usMensShoeSize
         const lArchStart = PixelToInches(
            user.footMeasurements.manualMeasure.leftArch.archStart,
            leftFootLength,
            parseFloat(shoeSize)
         ).toFixed(3)
         const lArchEnd = PixelToInches(
            user.footMeasurements.manualMeasure.leftArch.archEnd,
            leftFootLength,
            parseFloat(shoeSize)
         ).toFixed(3)
         const lArchHeightB = user.footMeasurements.manualMeasure.leftArch.archHeightIndex
         const rArchStart = PixelToInches(
            user.footMeasurements.manualMeasure.rightArch.archStart,
            rightFootLength,
            parseFloat(shoeSize)
         ).toFixed(3)
         const rArchEnd = PixelToInches(
            user.footMeasurements.manualMeasure.rightArch.archEnd,
            rightFootLength,
            parseFloat(shoeSize)
         ).toFixed(3)
         const rArchHeightB = user.footMeasurements.manualMeasure.rightArch.archHeightIndex
         const orderNumber = user.orderNumber
         const stlUserData = {
            shoeSize: parseFloat(shoeSize),
            lArchStart: parseFloat(lArchStart),
            lArchEnd: parseFloat(lArchEnd),
            lArchHeightB: parseFloat(lArchHeightB),
            rArchStart: parseFloat(rArchStart),
            rArchEnd: parseFloat(rArchEnd),
            rArchHeightB: parseFloat(rArchHeightB),
            orderNumber: orderNumber,
         }
         set(ref(stlDB, `Users/${user.id}`), stlUserData)
      }
      get(ref(stlDB, `numPendingRhino/`)).then((snapshot) => {
         const numPendingRhinoPrev = snapshot.val()
         update(ref(stlDB), { numPendingRhino: numPendingRhinoPrev + rowData.length }) // does this row data need to be changed?
      })
   }

   const removeFromDashboard = () => {
      for (const selectedUser of rowsChecked) {
         console.log('selectedUser.id:', selectedUser)
         let updates = {}
         updates['/Users/' + selectedUser + '/showInDashboard'] = false
         update(ref(db), updates)
      }
   }

   const removeFromDataForever = async () => {
      if (
         window.confirm(
            'Are you sure you want to remove all of these rows from firebase? You will NOT be able to recover the data if OK is selected.'
         )
      ) {
         for (const selectedUser of rowsChecked) {
            let updates = {}
            updates['/Users/' + selectedUser] = null
            update(ref(db), updates)

            const userImagesDBRef = sref(userImagesBucket, `/${selectedUser}/`)
            const userImageRef = await listAll(userImagesDBRef)
            const pathsToDelete = userImageRef.items.map((item) => item.fullPath)
            pathsToDelete.forEach((path) => {
               const storageRef = sref(userImagesBucket, `/${path}/`)
               deleteObject(storageRef)
            })
         }
      } else {
         // Do nothing!
         console.log('Thing was not saved to the database.')
      }
   }

   const resendRequest = async () => {
      if (block === 'NO PURCHASE') {
         for (const selectedUser of rowsChecked) {
            let updates = {}
            const randOrderNum = genRandOrderNum()
            updates['/purchases/' + selectedUser + '/orderNumber'] = randOrderNum
            updates['/Users/' + selectedUser + '/orderNumber'] = randOrderNum
            update(ref(db), updates)
         }
      } else if (block === 'Users without foot measurments') {
         for (const selectedUser of rowsChecked) {
            const leftArchPhotoRef = sref(userImagesBucket, `/${selectedUser}/LeftArch.jpg`)
            const rightArchPhotoRef = sref(userImagesBucket, `/${selectedUser}/RightArch.jpg`)
            const leftArchImg = await getBlob(leftArchPhotoRef)
            const rightArchImg = await getBlob(rightArchPhotoRef)

            await send2Backend(leftArchImg, selectedUser)
            await send2Backend(rightArchImg, selectedUser)
         }
      } else if (block === 'Re-Que') {
         for (const selectedUser of rowsChecked) {
            let updates = {}
            updates['/Users/' + selectedUser + '/showInDashboard'] = true
            updates['/Users/' + selectedUser + '/stlQueued'] = false
            updates['/Users/' + selectedUser + '/stlDownloaded'] = false
            updates['/Users/' + selectedUser + '/manufactoringEmailQueued'] = false
            update(ref(db), updates)
         }
      }
   }

   const downloadImages = async () => {
      for (const selectedUser of rowsChecked) {
         let jszip = new JSZip()
         console.log(`selectedUser: `, selectedUser)
         const leftArchPhotoRef = sref(userImagesBucket, `/${selectedUser}/LeftArch.jpg`)
         const rightArchPhotoRef = sref(userImagesBucket, `/${selectedUser}/RightArch.jpg`)
         const leftArchImg = await getBlob(leftArchPhotoRef)
         const rightArchImg = await getBlob(rightArchPhotoRef)
         jszip.file('LeftArch.jpg', leftArchImg)
         jszip.file('RightArch.jpg', rightArchImg)
         const content = await jszip.generateAsync({ type: 'blob' })
         saveAs(content, `${selectedUser}.zip`)
      }
   }

   const identify = async () => {
      for (const selectedUser of rowsChecked) {
         const user = data.find((elem) => elem.id === selectedUser)
         console.log(`${selectedUser}: `, JSON.parse(JSON.stringify(user)))
      }
   }

   const sendManufacturingEmail = async () => {
      for (const selectedUser of rowsChecked) {
         const user = data.find((elem) => elem.id === selectedUser)

         if (user.partnerName === 'protect3d') {
            const protect3dID = user.customerID

            var formdata = new FormData()
            formdata.append('authKey', 'DBgfYzR9sJ39VjEy')
            formdata.append('orderNumber', protect3dID)
            fetch('https://api.instryde.com/mailchimp/send_protect3d_manufacture_email', {
               method: 'POST',
               body: formdata,
            })
               .then((response) => response.text())
               .then((result) => {
                  console.log(result)
                  let updates = {}
                  updates['/Users/' + selectedUser + '/manufactoringEmailQueued'] = false
                  update(ref(db), updates)
               })
               .catch((error) => {
                  alert('Something went wrong, check console')
                  console.log('error', error)
               })
         }
      }
   }

   const genRandOrderNum = () => {
      return `randON-${Date.now()}`
   }

   const uploadAndSetSTLTrue = () => {
      setSTLTrue()
      uploadToSTL()
   }

   const displaySearchBar = () => {
      if (showViewColumnSelector) {
         return
      } else {
         setShowSearchBar(!showSearchBar)
      }
   }

   const displayViewColumnSelector = () => {
      if (showSearchBar) {
         return
      } else {
         setShowViewColumnSelector(!showViewColumnSelector)
      }
   }

   const searchAndFilter = (event) => {
      let filteredRowData = []

      if (event.target.value === '') {
         filteredRowData = []
      }

      selectedFilters.forEach((filterVar) => {
         const filteredBySearch = data.filter((item) => item[filterVar].includes(event.target.value))

         filteredRowData = filteredRowData.concat(filteredBySearch)
      })

      let noDuplicates = [...new Set(filteredRowData)]

      if (filteredRowData.length === 0 && event.target.value !== '') {
         return callbackRowData(['No matching records'])
      }

      return callbackRowData(noDuplicates)
   }

   const handleButtonFilterClick = (buttonId) => {
      setSelectedFilters([...selectedFilters, buttonId])
      if (selectedFilters.includes(buttonId)) {
         setSelectedFilters(selectedFilters.filter((item) => item !== buttonId))
      }
   }

   const handleViewColumnButtonClick = (buttonId) => {
      return callbackHideSelectedColumns(buttonId)
   }

   return (
      <div className={toolbarButtonStyles.container}>
         {showSearchBar && rowsChecked.length === 0 ? (
            <div>
               <input
                  type="text"
                  data-testid="searchTextBox"
                  placeholder="Search..."
                  onChange={(event) => searchAndFilter(event)}
                  className={toolbarButtonStyles.searchBar}
                  disabled={selectedFilters.length === 0}
               />
               <div className={toolbarButtonStyles.searchCheckBoxes}>
                  <button
                     data-testid="searchSessionIdButton"
                     className={[
                        selectedFilters.includes('id') === true
                           ? toolbarButtonStyles.filterSelected
                           : toolbarButtonStyles.filterNotSelected,
                     ]}
                     onClick={() => handleButtonFilterClick('id')}
                  >
                     Session ID
                  </button>
                  <button
                     data-testid="searchOrderNumberButton"
                     className={[
                        selectedFilters.includes('orderNumber') === true
                           ? toolbarButtonStyles.filterSelected
                           : toolbarButtonStyles.filterNotSelected,
                     ]}
                     onClick={() => handleButtonFilterClick('orderNumber')}
                  >
                     Order Number
                  </button>
                  <button
                     data-testid="searchProtect3dCIDButton"
                     className={[
                        selectedFilters.includes('protect3dCID') === true
                           ? toolbarButtonStyles.filterSelected
                           : toolbarButtonStyles.filterNotSelected,
                     ]}
                     onClick={() => handleButtonFilterClick('protect3dCID')}
                  >
                     Protect3d CID
                  </button>
                  <button
                     data-testid="searchFirstNameButton"
                     className={[
                        selectedFilters.includes('firstName') === true
                           ? toolbarButtonStyles.filterSelected
                           : toolbarButtonStyles.filterNotSelected,
                     ]}
                     onClick={() => handleButtonFilterClick('firstName')}
                  >
                     First Name
                  </button>
                  <button
                     data-testid="searchEmailButton"
                     className={[
                        selectedFilters.includes('email') === true
                           ? toolbarButtonStyles.filterSelected
                           : toolbarButtonStyles.filterNotSelected,
                     ]}
                     onClick={() => handleButtonFilterClick('email')}
                  >
                     Email
                  </button>
                  <button
                     data-testid="searchCreationDateButton"
                     className={[
                        selectedFilters.includes('mlTimestamp') === true
                           ? toolbarButtonStyles.filterSelected
                           : toolbarButtonStyles.filterNotSelected,
                     ]}
                     onClick={() => handleButtonFilterClick('mlTimestamp')}
                  >
                     Creation Date
                  </button>
               </div>
            </div>
         ) : (
            <></>
         )}

         {showViewColumnSelector && rowsChecked.length === 0 ? (
            <div>
               <div className={toolbarButtonStyles.searchCheckBoxes}>
                  <button
                     data-testid="columnSessionIdButton"
                     className={[
                        hideSelectedColumns.includes('id') === true
                           ? toolbarButtonStyles.filterNotSelected
                           : toolbarButtonStyles.filterSelected,
                     ]}
                     onClick={() => handleViewColumnButtonClick('id')}
                  >
                     Session ID
                  </button>
                  <button
                     data-testid="columnOrderNumberButton"
                     className={[
                        hideSelectedColumns.includes('orderNumber') === true
                           ? toolbarButtonStyles.filterNotSelected
                           : toolbarButtonStyles.filterSelected,
                     ]}
                     onClick={() => handleViewColumnButtonClick('orderNumber')}
                  >
                     Order Number
                  </button>
                  <button
                     data-testid="columnProtect3dCIDButton"
                     className={[
                        hideSelectedColumns.includes('protect3dCID') === true
                           ? toolbarButtonStyles.filterNotSelected
                           : toolbarButtonStyles.filterSelected,
                     ]}
                     onClick={() => handleViewColumnButtonClick('protect3dCID')}
                  >
                     Protect3d CID
                  </button>
                  <button
                     data-testid="columnFirstNameButton"
                     className={[
                        hideSelectedColumns.includes('firstName') === true
                           ? toolbarButtonStyles.filterNotSelected
                           : toolbarButtonStyles.filterSelected,
                     ]}
                     onClick={() => handleViewColumnButtonClick('firstName')}
                  >
                     First Name
                  </button>
                  <button
                     data-testid="columnEmailButton"
                     className={[
                        hideSelectedColumns.includes('email') === true
                           ? toolbarButtonStyles.filterNotSelected
                           : toolbarButtonStyles.filterSelected,
                     ]}
                     onClick={() => handleViewColumnButtonClick('email')}
                  >
                     Email
                  </button>
                  <button
                     data-testid="columnCreationDateButton"
                     className={[
                        hideSelectedColumns.includes('mlTimestamp') === true
                           ? toolbarButtonStyles.filterNotSelected
                           : toolbarButtonStyles.filterSelected,
                     ]}
                     onClick={() => handleViewColumnButtonClick('mlTimestamp')}
                  >
                     Creation Date
                  </button>
               </div>
            </div>
         ) : (
            <></>
         )}

         {rowsChecked.length !== 0 ? (
            <div className={toolbarButtonStyles.container}>
               <Button title={'Identify'} image={<Fingerprint />} buttonFunction={identify} />
               {showToolbarButtons && (
                  <Button title={'Upload'} image={<PublishIcon />} buttonFunction={uploadAndSetSTLTrue} />
               )}
               <Button title={'Delete'} image={<DeleteIcon />} buttonFunction={removeFromDataForever} />
               <Button title={'Remove'} image={<RemoveCircleOutlineIcon />} buttonFunction={removeFromDashboard} />
               {(block === 'NO PURCHASE' || block === 'Users without foot measurments' || block === 'Re-Que') && (
                  <Button title={'Resend'} image={<RotatedLeft />} buttonFunction={resendRequest} />
               )}
               {block !== 'NO SCAN IMAGES' && (
                  <Button title={'Images'} image={<CollectionsIcon />} buttonFunction={downloadImages} />
               )}
               {block === 'Send Manufacturing Email' && (
                  <Button title={'Send Email'} image={<Email />} buttonFunction={sendManufacturingEmail} />
               )}
            </div>
         ) : (
            <div className={toolbarButtonStyles.container}>
               <Button title={'Search'} image={<SearchIcon />} buttonFunction={displaySearchBar} />
               <Button title={'Columns'} image={<ViewColumnIcon />} buttonFunction={displayViewColumnSelector} />
            </div>
         )}
      </div>
   )
}
